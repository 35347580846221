@charset "UTF-8";

@import url("https://fonts.googleapis.com/css?family=Nunito");

@import url("https://fonts.googleapis.com/css?family=Montserrat:200,300,400,500,600,700,800|Open+Sans:300,400,600,700");

@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700|Dosis:200,400,500,600");

/*!
 * Bootstrap v4.5.3 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

@import '~jquery-confirm/dist/jquery-confirm.min.css';

:root {
  --blue: #3490dc;
  --indigo: #6574cd;
  --purple: #9561e2;
  --pink: #f66d9b;
  --red: #e3342f;
  --orange: #f6993f;
  --yellow: #ffed4a;
  --green: #38c172;
  --teal: #4dc0b5;
  --cyan: #6cb2eb;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #38c172;
  --secondary: #3490dc;
  --success: #38c172;
  --info: #6cb2eb;
  --warning: #ffed4a;
  --danger: #e3342f;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Nunito", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: "Nunito", sans-serif;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.6;
  color: #212529;
  text-align: left;
  background-color: #f8fafc;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #38c172;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #27864f;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1,
.h1 {
  font-size: 2.25rem;
}

h2,
.h2 {
  font-size: 1.8rem;
}

h3,
.h3 {
  font-size: 1.575rem;
}

h4,
.h4 {
  font-size: 1.35rem;
}

h5,
.h5 {
  font-size: 1.125rem;
}

h6,
.h6 {
  font-size: 0.9rem;
}

.lead {
  font-size: 1.125rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.125rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #f8fafc;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #f66d9b;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm,
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container-md,
  .container-sm,
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1140px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c7eed8;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #98dfb6;
}

.table-hover .table-primary:hover {
  background-color: #b3e8ca;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #b3e8ca;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #c6e0f5;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #95c5ed;
}

.table-hover .table-secondary:hover {
  background-color: #b0d4f1;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #b0d4f1;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c7eed8;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #98dfb6;
}

.table-hover .table-success:hover {
  background-color: #b3e8ca;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b3e8ca;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #d6e9f9;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #b3d7f5;
}

.table-hover .table-info:hover {
  background-color: #c0ddf6;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #c0ddf6;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fffacc;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #fff6a1;
}

.table-hover .table-warning:hover {
  background-color: #fff8b3;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #fff8b3;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f7c6c5;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f09593;
}

.table-hover .table-danger:hover {
  background-color: #f4b0af;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f4b0af;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.6em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.6;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #98e1b7;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(56, 193, 114, 0.25);
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.6;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.125rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.7875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 0.9rem;
  line-height: 1.6;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.7875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size],
select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #38c172;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.7875rem;
  line-height: 1.6;
  color: #fff;
  background-color: rgba(56, 193, 114, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: #38c172;
  padding-right: calc(1.6em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2338c172' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.4em + 0.1875rem) center;
  background-size: calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  border-color: #38c172;
  box-shadow: 0 0 0 0.2rem rgba(56, 193, 114, 0.25);
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.6em + 0.75rem);
  background-position: top calc(0.4em + 0.1875rem) right calc(0.4em + 0.1875rem);
}

.was-validated .custom-select:valid,
.custom-select.is-valid {
  border-color: #38c172;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2338c172' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
}

.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #38c172;
  box-shadow: 0 0 0 0.2rem rgba(56, 193, 114, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: #38c172;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip,
.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label {
  color: #38c172;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before,
.custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #38c172;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before,
.custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #5cd08d;
  background-color: #5cd08d;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before,
.custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(56, 193, 114, 0.25);
}

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before,
.custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #38c172;
}

.was-validated .custom-file-input:valid ~ .custom-file-label,
.custom-file-input.is-valid ~ .custom-file-label {
  border-color: #38c172;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label,
.custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #38c172;
  box-shadow: 0 0 0 0.2rem rgba(56, 193, 114, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #e3342f;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.7875rem;
  line-height: 1.6;
  color: #fff;
  background-color: rgba(227, 52, 47, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #e3342f;
  padding-right: calc(1.6em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e3342f' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e3342f' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.4em + 0.1875rem) center;
  background-size: calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: #e3342f;
  box-shadow: 0 0 0 0.2rem rgba(227, 52, 47, 0.25);
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.6em + 0.75rem);
  background-position: top calc(0.4em + 0.1875rem) right calc(0.4em + 0.1875rem);
}

.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: #e3342f;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e3342f' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e3342f' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
}

.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #e3342f;
  box-shadow: 0 0 0 0.2rem rgba(227, 52, 47, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: #e3342f;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip,
.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label,
.custom-control-input.is-invalid ~ .custom-control-label {
  color: #e3342f;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before,
.custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #e3342f;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before,
.custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e9605c;
  background-color: #e9605c;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(227, 52, 47, 0.25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #e3342f;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label,
.custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #e3342f;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label,
.custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #e3342f;
  box-shadow: 0 0 0 0.2rem rgba(227, 52, 47, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }

  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }

  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }

  .form-inline .form-control-plaintext {
    display: inline-block;
  }

  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }

  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }

  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }

  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }

  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.9rem;
  line-height: 1.6;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #212529;
  text-decoration: none;
}

.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(56, 193, 114, 0.25);
}

.btn.disabled,
.btn:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #38c172;
  border-color: #38c172;
}

.btn-primary:hover {
  color: #fff;
  background-color: #2fa360;
  border-color: #2d995b;
}

.btn-primary:focus,
.btn-primary.focus {
  color: #fff;
  background-color: #2fa360;
  border-color: #2d995b;
  box-shadow: 0 0 0 0.2rem rgba(86, 202, 135, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #38c172;
  border-color: #38c172;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #2d995b;
  border-color: #2a9055;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(86, 202, 135, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #3490dc;
  border-color: #3490dc;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #227dc7;
  border-color: #2176bd;
}

.btn-secondary:focus,
.btn-secondary.focus {
  color: #fff;
  background-color: #227dc7;
  border-color: #2176bd;
  box-shadow: 0 0 0 0.2rem rgba(82, 161, 225, 0.5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #3490dc;
  border-color: #3490dc;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #2176bd;
  border-color: #1f6fb2;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 161, 225, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #38c172;
  border-color: #38c172;
}

.btn-success:hover {
  color: #fff;
  background-color: #2fa360;
  border-color: #2d995b;
}

.btn-success:focus,
.btn-success.focus {
  color: #fff;
  background-color: #2fa360;
  border-color: #2d995b;
  box-shadow: 0 0 0 0.2rem rgba(86, 202, 135, 0.5);
}

.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #38c172;
  border-color: #38c172;
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #2d995b;
  border-color: #2a9055;
}

.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(86, 202, 135, 0.5);
}

.btn-info {
  color: #212529;
  background-color: #6cb2eb;
  border-color: #6cb2eb;
}

.btn-info:hover {
  color: #fff;
  background-color: #4aa0e6;
  border-color: #3f9ae5;
}

.btn-info:focus,
.btn-info.focus {
  color: #fff;
  background-color: #4aa0e6;
  border-color: #3f9ae5;
  box-shadow: 0 0 0 0.2rem rgba(97, 157, 206, 0.5);
}

.btn-info.disabled,
.btn-info:disabled {
  color: #212529;
  background-color: #6cb2eb;
  border-color: #6cb2eb;
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #3f9ae5;
  border-color: #3495e3;
}

.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(97, 157, 206, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffed4a;
  border-color: #ffed4a;
}

.btn-warning:hover {
  color: #212529;
  background-color: #ffe924;
  border-color: #ffe817;
}

.btn-warning:focus,
.btn-warning.focus {
  color: #212529;
  background-color: #ffe924;
  border-color: #ffe817;
  box-shadow: 0 0 0 0.2rem rgba(222, 207, 69, 0.5);
}

.btn-warning.disabled,
.btn-warning:disabled {
  color: #212529;
  background-color: #ffed4a;
  border-color: #ffed4a;
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffe817;
  border-color: #ffe70a;
}

.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 207, 69, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #e3342f;
  border-color: #e3342f;
}

.btn-danger:hover {
  color: #fff;
  background-color: #d0211c;
  border-color: #c51f1a;
}

.btn-danger:focus,
.btn-danger.focus {
  color: #fff;
  background-color: #d0211c;
  border-color: #c51f1a;
  box-shadow: 0 0 0 0.2rem rgba(231, 82, 78, 0.5);
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #e3342f;
  border-color: #e3342f;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #c51f1a;
  border-color: #b91d19;
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(231, 82, 78, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus,
.btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus,
.btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #38c172;
  border-color: #38c172;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #38c172;
  border-color: #38c172;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(56, 193, 114, 0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #38c172;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #38c172;
  border-color: #38c172;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(56, 193, 114, 0.5);
}

.btn-outline-secondary {
  color: #3490dc;
  border-color: #3490dc;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #3490dc;
  border-color: #3490dc;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 144, 220, 0.5);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #3490dc;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #3490dc;
  border-color: #3490dc;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 144, 220, 0.5);
}

.btn-outline-success {
  color: #38c172;
  border-color: #38c172;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #38c172;
  border-color: #38c172;
}

.btn-outline-success:focus,
.btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(56, 193, 114, 0.5);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #38c172;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #38c172;
  border-color: #38c172;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(56, 193, 114, 0.5);
}

.btn-outline-info {
  color: #6cb2eb;
  border-color: #6cb2eb;
}

.btn-outline-info:hover {
  color: #212529;
  background-color: #6cb2eb;
  border-color: #6cb2eb;
}

.btn-outline-info:focus,
.btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 178, 235, 0.5);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #6cb2eb;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #212529;
  background-color: #6cb2eb;
  border-color: #6cb2eb;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 178, 235, 0.5);
}

.btn-outline-warning {
  color: #ffed4a;
  border-color: #ffed4a;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffed4a;
  border-color: #ffed4a;
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 237, 74, 0.5);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ffed4a;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffed4a;
  border-color: #ffed4a;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 237, 74, 0.5);
}

.btn-outline-danger {
  color: #e3342f;
  border-color: #e3342f;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #e3342f;
  border-color: #e3342f;
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(227, 52, 47, 0.5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #e3342f;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #e3342f;
  border-color: #e3342f;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(227, 52, 47, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus,
.btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus,
.btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #38c172;
  text-decoration: none;
}

.btn-link:hover {
  color: #27864f;
  text-decoration: underline;
}

.btn-link:focus,
.btn-link.focus {
  text-decoration: underline;
}

.btn-link:disabled,
.btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg,
.btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm,
.btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.7875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.9rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top],
.dropdown-menu[x-placement^=right],
.dropdown-menu[x-placement^=bottom],
.dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #38c172;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.7875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: flex;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.6;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.7875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.44rem;
  padding-left: 1.5rem;
  color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.22rem;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #38c172;
  background-color: #38c172;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(56, 193, 114, 0.25);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #98e1b7;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #bfecd2;
  border-color: #bfecd2;
}

.custom-control-input[disabled] ~ .custom-control-label,
.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}

.custom-control-input[disabled] ~ .custom-control-label::before,
.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-control-label::before {
  position: absolute;
  top: 0.22rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}

.custom-control-label::after {
  position: absolute;
  top: 0.22rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50%/50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #38c172;
  background-color: #38c172;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(56, 193, 114, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(56, 193, 114, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(56, 193, 114, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}

.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}

.custom-switch .custom-control-label::after {
  top: calc(0.22rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(56, 193, 114, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.6em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.6;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}

.custom-select:focus {
  border-color: #98e1b7;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(56, 193, 114, 0.25);
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.7875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.125rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.6em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.6em + 0.75rem + 2px);
  margin: 0;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #98e1b7;
  box-shadow: 0 0 0 0.2rem rgba(56, 193, 114, 0.25);
}

.custom-file-input[disabled] ~ .custom-file-label,
.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.6em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.6;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.6em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.6;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}

.custom-range:focus {
  outline: none;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #f8fafc, 0 0 0 0.2rem rgba(56, 193, 114, 0.25);
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #f8fafc, 0 0 0 0.2rem rgba(56, 193, 114, 0.25);
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #f8fafc, 0 0 0 0.2rem rgba(56, 193, 114, 0.25);
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #38c172;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #bfecd2;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #38c172;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #bfecd2;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #38c172;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #bfecd2;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover,
.nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #f8fafc;
  border-color: #dee2e6 #dee2e6 #f8fafc;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #38c172;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.32rem;
  padding-bottom: 0.32rem;
  margin-right: 1rem;
  font-size: 1.125rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover,
.navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.125rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.navbar-toggler:hover,
.navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }

  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }

  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }

  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }

  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}

.accordion > .card {
  overflow: hidden;
}

.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item {
  display: flex;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #38c172;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  z-index: 2;
  color: #27864f;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(56, 193, 114, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #38c172;
  border-color: #38c172;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.125rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.7875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}

a.badge:hover,
a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #38c172;
}

a.badge-primary:hover,
a.badge-primary:focus {
  color: #fff;
  background-color: #2d995b;
}

a.badge-primary:focus,
a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(56, 193, 114, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #3490dc;
}

a.badge-secondary:hover,
a.badge-secondary:focus {
  color: #fff;
  background-color: #2176bd;
}

a.badge-secondary:focus,
a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 144, 220, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #38c172;
}

a.badge-success:hover,
a.badge-success:focus {
  color: #fff;
  background-color: #2d995b;
}

a.badge-success:focus,
a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(56, 193, 114, 0.5);
}

.badge-info {
  color: #212529;
  background-color: #6cb2eb;
}

a.badge-info:hover,
a.badge-info:focus {
  color: #212529;
  background-color: #3f9ae5;
}

a.badge-info:focus,
a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 178, 235, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffed4a;
}

a.badge-warning:hover,
a.badge-warning:focus {
  color: #212529;
  background-color: #ffe817;
}

a.badge-warning:focus,
a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 237, 74, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #e3342f;
}

a.badge-danger:hover,
a.badge-danger:focus {
  color: #fff;
  background-color: #c51f1a;
}

a.badge-danger:focus,
a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(227, 52, 47, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

a.badge-light:hover,
a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}

a.badge-light:focus,
a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

a.badge-dark:hover,
a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}

a.badge-dark:focus,
a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3.85rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #1d643b;
  background-color: #d7f3e3;
  border-color: #c7eed8;
}

.alert-primary hr {
  border-top-color: #b3e8ca;
}

.alert-primary .alert-link {
  color: #123c24;
}

.alert-secondary {
  color: #1b4b72;
  background-color: #d6e9f8;
  border-color: #c6e0f5;
}

.alert-secondary hr {
  border-top-color: #b0d4f1;
}

.alert-secondary .alert-link {
  color: #113049;
}

.alert-success {
  color: #1d643b;
  background-color: #d7f3e3;
  border-color: #c7eed8;
}

.alert-success hr {
  border-top-color: #b3e8ca;
}

.alert-success .alert-link {
  color: #123c24;
}

.alert-info {
  color: #385d7a;
  background-color: #e2f0fb;
  border-color: #d6e9f9;
}

.alert-info hr {
  border-top-color: #c0ddf6;
}

.alert-info .alert-link {
  color: #284257;
}

.alert-warning {
  color: #857b26;
  background-color: #fffbdb;
  border-color: #fffacc;
}

.alert-warning hr {
  border-top-color: #fff8b3;
}

.alert-warning .alert-link {
  color: #5d561b;
}

.alert-danger {
  color: #761b18;
  background-color: #f9d6d5;
  border-color: #f7c6c5;
}

.alert-danger hr {
  border-top-color: #f4b0af;
}

.alert-danger .alert-link {
  color: #4c110f;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light hr {
  border-top-color: #ececf6;
}

.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.alert-dark hr {
  border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }

  to {
    background-position: 0 0;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.675rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #38c172;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #38c172;
  border-color: #38c172;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }

  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }

  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }

  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }

  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #1d643b;
  background-color: #c7eed8;
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #1d643b;
  background-color: #b3e8ca;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #1d643b;
  border-color: #1d643b;
}

.list-group-item-secondary {
  color: #1b4b72;
  background-color: #c6e0f5;
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  color: #1b4b72;
  background-color: #b0d4f1;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #1b4b72;
  border-color: #1b4b72;
}

.list-group-item-success {
  color: #1d643b;
  background-color: #c7eed8;
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  color: #1d643b;
  background-color: #b3e8ca;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #1d643b;
  border-color: #1d643b;
}

.list-group-item-info {
  color: #385d7a;
  background-color: #d6e9f9;
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  color: #385d7a;
  background-color: #c0ddf6;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #385d7a;
  border-color: #385d7a;
}

.list-group-item-warning {
  color: #857b26;
  background-color: #fffacc;
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  color: #857b26;
  background-color: #fff8b3;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #857b26;
  border-color: #857b26;
}

.list-group-item-danger {
  color: #761b18;
  background-color: #f7c6c5;
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  color: #761b18;
  background-color: #f4b0af;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #761b18;
  border-color: #761b18;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.35rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

.close:hover {
  color: #000;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}

.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.6;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }

  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.7875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top,
.bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow,
.bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right,
.bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow,
.bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left,
.bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.7875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}

.popover .arrow::before,
.popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top,
.bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}

.bs-popover-top > .arrow,
.bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-top > .arrow::before,
.bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top > .arrow::after,
.bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right,
.bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}

.bs-popover-right > .arrow,
.bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-right > .arrow::before,
.bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right > .arrow::after,
.bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom,
.bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}

.bs-popover-bottom > .arrow,
.bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-bottom > .arrow::before,
.bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .arrow::after,
.bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left,
.bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}

.bs-popover-left > .arrow,
.bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-left > .arrow::before,
.bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left > .arrow::after,
.bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.9rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50%/100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #38c172 !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #2d995b !important;
}

.bg-secondary {
  background-color: #3490dc !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #2176bd !important;
}

.bg-success {
  background-color: #38c172 !important;
}

a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #2d995b !important;
}

.bg-info {
  background-color: #6cb2eb !important;
}

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #3f9ae5 !important;
}

.bg-warning {
  background-color: #ffed4a !important;
}

a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #ffe817 !important;
}

.bg-danger {
  background-color: #e3342f !important;
}

a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #c51f1a !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #38c172 !important;
}

.border-secondary {
  border-color: #3490dc !important;
}

.border-success {
  border-color: #38c172 !important;
}

.border-info {
  border-color: #6cb2eb !important;
}

.border-warning {
  border-color: #ffed4a !important;
}

.border-danger {
  border-color: #e3342f !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #38c172 !important;
}

a.text-primary:hover,
a.text-primary:focus {
  color: #27864f !important;
}

.text-secondary {
  color: #3490dc !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
  color: #1d68a7 !important;
}

.text-success {
  color: #38c172 !important;
}

a.text-success:hover,
a.text-success:focus {
  color: #27864f !important;
}

.text-info {
  color: #6cb2eb !important;
}

a.text-info:hover,
a.text-info:focus {
  color: #298fe2 !important;
}

.text-warning {
  color: #ffed4a !important;
}

a.text-warning:hover,
a.text-warning:focus {
  color: #fde300 !important;
}

.text-danger {
  color: #e3342f !important;
}

a.text-danger:hover,
a.text-danger:focus {
  color: #ae1c17 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover,
a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover,
a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

@page {
    size: a3;
}

  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }

  .table td,
  .table th {
    background-color: #fff !important;
  }

  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }

  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}

/* ===================================
          Table of Contents
   =================================== */

/*
01 body
02 Helper Classes
   02-1 short codes
   02-2 loader
03 navigation
04 owl & revolution
05 Page header
06 about_us
   - services-slider
   - services-main
   - Work Process
   - App Features
   - horizontal-section
   - Counters
   - Progress bars
   - Faq Accordions
07 Team
  - team-content
  - team-box
  - overlay
08 Testimonial
09 gallery & Portfolio
10 contact us & forms
11 Partner
12 contact us
13 Our Blog
14 404 Error
15 Shop
    - Cart
    - Shop Main
    - Shop Details & Blog
        - Tabs Accordion
        - Carousel
        - Dates
        - Account
        - Pagination
        - Comments
        - Widget Newsletter
        - Add Review Form
        - Product Tags
        - Side Bar Info
        - Categories
16 coming soon
17 Pricing Table
18 Footer
19 Media Queries

/*Table Of Contents ends */

/*Global Styling*/

body {
  font-weight: normal;
  font-family: "Open Sans", sans-serif;
  overflow-x: hidden;
  direction: ltr;
  font-size: 0.9375rem;
  font-weight: 300;
  line-height: 1.9;
  color: #757575;
  text-align: left;
  background-color: #fff;
}

::selection {
  background: rgba(3, 169, 245, 0.5) !important;
  color: #fff;
}

/*
table,
tr,
td,
blockquote {
    color: #a5a5a5;
}
*/

html {
  font-size: 16px;
}

ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a,
a:hover,
a:focus {
  outline: none;
  text-decoration: none;
  color: inherit;
}

button:focus,
button:active,
input:focus,
input:active {
  outline: none;
  box-shadow: none;
}

small {
  font-size: 60%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  color: #323d47;
  letter-spacing: 0.5px;
}

h1,
h2,
h3,
h4,
h5,
.button,
.numbered .tp-bullet .tp-count {
  font-family: "Montserrat", sans-serif;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 3rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.125rem;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 0.875rem;
}

p {
  font-size: 14px;
}

p.title {
  font-size: 1rem;
}

.italic {
  font-style: italic;
}

button {
  line-height: 1;
}

/* Helper Classes & Short-codes */

p {
  font-weight: normal;
  line-height: 1.7;
}

.padding {
  padding: 7.5rem 0;
}

.padding_testi {
  padding: 7.5rem 0;
}

.padding_top {
  padding-top: 7.5rem;
}

.padding_bottom {
  padding-bottom: 7.5rem;
}

.margin {
  margin: 7.5rem 0;
}

.margin_bottom {
  margin-bottom: 7.5rem;
}

.margin_top {
  margin-top: 7.5rem;
}

.padding_half {
  padding: 3.75rem 0;
}

.margin_half {
  margin: 3.75rem 0;
}

.padding_bottom_half {
  padding-bottom: 3.75rem;
}

.margin_bottom_half {
  margin-bottom: 3.75rem;
}

.padding_top_half {
  padding-top: 3.75rem;
}

.heading_space {
  margin-bottom: 3.75rem;
}

.heading_space_half {
  margin-bottom: 2.25rem;
}

.bottom5 {
  margin-bottom: 5px;
}

.bottom10 {
  margin-bottom: 10px;
}

.top5 {
  margin-top: 5px;
}

.top10 {
  margin-top: 10px;
}

.bottom15 {
  margin-bottom: 15px;
}

.top15 {
  margin-top: 15px;
}

.top20 {
  margin-top: 20px;
}

.bottom20 {
  margin-bottom: 20px;
}

.bottom25 {
  margin-bottom: 25px;
}

.top25 {
  margin-top: 25px;
}

.bottom30 {
  margin-bottom: 30px;
}

.top30 {
  margin-top: 30px;
}

.bottom35 {
  margin-bottom: 35px;
}

.top40 {
  margin-top: 40px;
}

.bottom40 {
  margin-bottom: 40px;
}

.bottom45 {
  margin-bottom: 45px;
}

.bottom50 {
  margin-bottom: 50px;
}

.bottom60 {
  margin-bottom: 60px;
}

.top50 {
  margin-top: 50px;
}

.top60 {
  margin-top: 60px;
}

.top90 {
  margin-top: 90px;
  line-height: 1.4;
}

.bottom60 {
  margin-bottom: 60px;
}

.bottom0 {
  margin-bottom: 0 !important;
}

.margin_tophalf {
  margin-top: 3.75rem;
}

.no-margin {
  margin: 0;
}

.no-padding {
  padding: 0;
}

.pb-60 {
  padding-bottom: 60px;
}

.font-bold {
  font-weight: bold;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: normal;
}

.font-light {
  font-weight: 300;
}

.font-light2 {
  font-weight: 200;
}

.font-xlight {
  font-weight: 100;
}

.font-bold {
  font-weight: bold;
}

.font-medium {
  font-weight: 500;
}

.font-404-heading {
  font-size: 130px;
}

.coming-soon-heading {
  font-size: 85px;
}

.font-11 {
  font-size: 11px;
}

.font-12 {
  font-size: 12px;
}

.font-13 {
  font-size: 13px !important;
}

.font-14 {
  font-size: 14px;
}

.font-15 {
  font-size: 15px;
}

.font-16 {
  font-size: 16px;
}

.font-17 {
  font-size: 17px;
}

.font-18 {
  font-size: 18px;
}

.font-19 {
  font-size: 19px;
}

.font-large {
  font-size: large;
}

.bglight {
  background: #f6f7f8;
}

.bgdefault {
  background: #38c172;
}

.bgdark {
  background: #26313c;
}

.whitecolor {
  color: #ffffff;
}

.darkcolor {
  color: #384141;
}

.defaultcolor {
  color: #38c172;
}

.container-padding {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.hover-default:hover,
.hover-default:focus {
  color: #38c172;
}

.tparrows:hover {
  background: transparent;
}

/*heading Titles */

.heading-title h2 {
  line-height: 1;
  font-family: Dosis, sans-serif;
}

.heading-title > span {
  font-size: 1.125rem;
  font-weight: normal;
  margin-bottom: 10px;
  display: block;
}

.heading {
  color: #696969;
}

.line-height-17 {
  line-height: 1.7;
}

.heading_small h3 {
  font-size: 1.5rem;
}

.heading_small span {
  font-size: 1rem;
}

/*img wrap*/

.image {
  overflow: hidden;
}

a.image,
.image img {
  display: block;
}

.image img {
  width: 100%;
}

section,
.image,
.image img {
  position: relative;
}

.login-image img {
  transition: all 300ms linear;
}

.login-image:hover img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}

.coming-soon-image:hover img {
  transform: scale(1.1);
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-1 {
  opacity: 0.1 !important;
}

.opacity-2 {
  opacity: 0.2 !important;
}

.opacity-3 {
  opacity: 0.3 !important;
}

.opacity-4 {
  opacity: 0.4 !important;
}

.opacity-5 {
  opacity: 0.5 !important;
}

.opacity-6 {
  opacity: 0.6 !important;
}

.opacity-7 {
  opacity: 0.7 !important;
}

.opacity-8 {
  opacity: 0.8 !important;
}

.opacity-9 {
  opacity: 0.9 !important;
}

.opacity-10 {
  opacity: 1 !important;
}

.z-index-n1 {
  z-index: -1 !important;
}

.z-index-0 {
  z-index: 0 !important;
}

.z-index-1 {
  z-index: 1 !important;
}

.z-index-2 {
  z-index: 2 !important;
}

.z-index-3 {
  z-index: 3 !important;
}

.z-index-4 {
  z-index: 4 !important;
}

.z-index-5 {
  z-index: 5 !important;
}

.z-index-6 {
  z-index: 6 !important;
}

.z-index-7 {
  z-index: 7 !important;
}

.z-index-8 {
  z-index: 8 !important;
}

.z-index-9 {
  z-index: 9 !important;
}

.z-index-10 {
  z-index: 10 !important;
}

.z-index-11 {
  z-index: 11 !important;
}

.z-index-12 {
  z-index: 12 !important;
}

.z-index-13 {
  z-index: 13 !important;
}

.z-index-14 {
  z-index: 14 !important;
}

.z-index-15 {
  z-index: 15 !important;
}

.z-index-16 {
  z-index: 16 !important;
}

.z-index-17 {
  z-index: 17 !important;
}

.z-index-n1 {
  z-index: -1;
}

.scale-price {
  transform: scale(0.6) !important;
  opacity: 0;
}

.scale-star {
  transform: scale(0.5) !important;
}

/*center block*/

.center-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/*Back To Top*/

.back-top {
  right: 20px;
  font-size: 26px;
  position: fixed;
  z-index: 1600;
  opacity: 0;
  visibility: hidden;
  bottom: 90px;
  -webkit-background-size: 200% auto;
  background-size: 200% auto;
  background-color: #38c172;
  /*background-image: -webkit-gradient(linear, left top, right top, from($primary), color-stop(51%, #23ced5), to($primary));
  background-image: -webkit-linear-gradient(left, $primary 0%, #23ced5 51%, $primary 100%);
  background-image: -moz-linear-gradient(left, $primary 0%, #23ced5 51%, $primary 100%);
  background-image: -o-linear-gradient(left, $primary 0%, #23ced5 51%, $primary 100%);
  background-image: linear-gradient(to right, $primary 0%, #23ced5 51%, $primary 100%);*/
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  cursor: pointer;
}

.back-top:hover {
  background-color: #f6993f;
}

.back-top-visible {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  visibility: visible;
}

.back-top,
.back-top:hover,
.back-top:focus {
  color: #fff;
}

.back-top:hover {
  background-position: right center;
}

/* ---- PreLoader ---- */

.loader {
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1111;
  overflow-x: hidden;
  background: #fff;
}

.loader-inner {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.cssload-loader {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin: 2em;
  display: inline-block;
  position: relative;
  vertical-align: middle;
}

.cssload-loader,
.cssload-loader:before,
.cssload-loader:after {
  animation: 1.15s infinite ease-in-out;
  -o-animation: 1.15s infinite ease-in-out;
  -webkit-animation: 1.15s infinite ease-in-out;
  -moz-animation: 1.15s infinite ease-in-out;
}

.cssload-loader:before,
.cssload-loader:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #38c172;
  transform: scale(0);
  -o-transform: scale(0);
  -ms-transform: scale(0);
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  animation: cssload-animation 1.73s infinite ease-in-out;
  -o-animation: cssload-animation 1.73s infinite ease-in-out;
  -webkit-animation: cssload-animation 1.73s infinite ease-in-out;
  -moz-animation: cssload-animation 1.73s infinite ease-in-out;
}

.cssload-loader:after {
  animation-delay: 0.86s;
  -o-animation-delay: 0.86s;
  -webkit-animation-delay: 0.86s;
  -moz-animation-delay: 0.86s;
}

@keyframes cssload-animation {
  0% {
    transform: translateX(-100%) scale(0);
  }

  50% {
    transform: translateX(0%) scale(1);
  }

  100% {
    transform: translateX(100%) scale(0);
  }
}

@-o-keyframes cssload-animation {
  0% {
    -o-transform: translateX(-100%) scale(0);
  }

  50% {
    -o-transform: translateX(0%) scale(1);
  }

  100% {
    -o-transform: translateX(100%) scale(0);
  }
}

@-ms-keyframes cssload-animation {
  0% {
    -ms-transform: translateX(-100%) scale(0);
  }

  50% {
    -ms-transform: translateX(0%) scale(1);
  }

  100% {
    -ms-transform: translateX(100%) scale(0);
  }
}

@-webkit-keyframes cssload-animation {
  0% {
    -webkit-transform: translateX(-100%) scale(0);
  }

  50% {
    -webkit-transform: translateX(0%) scale(1);
  }

  100% {
    -webkit-transform: translateX(100%) scale(0);
  }
}

@-moz-keyframes cssload-animation {
  0% {
    -moz-transform: translateX(-100%) scale(0);
  }

  50% {
    -moz-transform: translateX(0%) scale(1);
  }

  100% {
    -moz-transform: translateX(100%) scale(0);
  }
}

/* ---- Loader ends ---- */

/* ---- gray index ---- */

.gray {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

/* ---- gray index ends ---- */

/* portfolio index*/

#rev_interactive {
  pointer-events: none;
}

#rev_interactive .tp-bullets {
  pointer-events: auto;
}

#rev_interactive .tp-bullets .tp-bullet {
  border-radius: 50%;
  overflow: hidden;
}

#rev_interactive .tp-bullets .tp-bullet:hover,
#rev_interactive .tp-bullets .tp-bullet.selected {
  background: rgba(255, 255, 255, 0.3);
}

#rev_interactive .tp-bullets .tp-bullet.active {
  cursor: default;
}

#rev_interactive .tp-bullets .tp-bullet:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #38c172;
  transform: translateY(100%);
}

#rev_interactive .tp-bullets .tp-bullet.active:before {
  transform: translateY(0);
}

#rev_one_page .tp-bullet:not(.selected):hover {
  background: rgba(255, 255, 255, 0.3);
}

#rev_one_page .tp-bullet {
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.07);
}

#rev_one_page .tp-bullet.selected {
  border-color: #fff;
}

.tp-bullet.selected {
  background: #38c172;
  cursor: default;
}

.tp-bullets .tp-bullet {
  -webkit-transition: 0.3s all;
  -moz-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
}

/* portfolio index ends*/

/*index creative bullet*/

#rev_creative .tp-bullet {
  border-radius: 50%;
  background: transparent !important;
  border: 1px solid #38c172;
  overflow: hidden;
}

#rev_creative .tp-bullet:before {
  content: "";
  position: absolute;
  width: 120%;
  height: 120%;
  background: transparent;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  box-sizing: initial;
}

#rev_creative .tp-bullet.selected:before {
  height: 60%;
  width: 60%;
  background: #38c172;
}

/*index creative bullet ends*/

/*modern design vertical navigation tp-bullets*/

#vertical-bullets.vertical-tpb .tp-bullets {
  height: auto !important;
  width: auto !important;
  text-align: center;
  display: inline-block;
  position: relative;
}

#vertical-bullets.vertical-tpb .tp-bullet {
  position: relative;
  display: contents;
  background: transparent;
  cursor: pointer;
  box-sizing: content-box;
}

#vertical-bullets.vertical-tpb .tp-bullet .tp-bullet-inner {
  width: 18px;
  height: 18px;
  position: relative;
  border: 4px solid #fff;
  border-radius: 50%;
  background: transparent;
  cursor: pointer;
  box-sizing: content-box;
  transform: scale(0.6);
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

#vertical-bullets.vertical-tpb .tp-bullet.selected .tp-bullet-inner {
  transform: scale(1);
  background: transparent;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

#vertical-bullets.vertical-tpb .tp-bullet .tp-bullet-inner::before {
  content: "";
  position: absolute;
  width: 60%;
  height: 60%;
  background: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  opacity: 0;
  visibility: hidden;
  display: none;
}

#vertical-bullets.vertical-tpb .tp-bullet.selected .tp-bullet-inner::before {
  opacity: 1;
  visibility: visible;
  display: block;
}

#vertical-bullets.vertical-tpb .tp-bullet:not(:last-child) .tp-line {
  height: 20px;
  transform: scaleY(1.4);
  width: 3px;
  background: #fff;
  display: block;
  margin: 0 auto;
  pointer-events: none;
}

.header-with-topbar .top-header-area {
  font-size: 13px;
  top: 0;
  z-index: 21;
  height: 50px;
  line-height: 50px;
  width: 100%;
  position: absolute;
  transition: ease-in-out 0.3s;
  -webkit-transition: ease-in-out 0.3s;
  -moz-transition: ease-in-out 0.3s;
  -o-transition: ease-in-out 0.3s;
  background: transparent;
}

.header-with-topbar nav {
  margin-top: 50px;
}

/*start up index*/

.startup-slider {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.startup-img {
  position: relative;
  -webkit-transition: 0.3s all;
  -moz-transition: 0.3s all;
  transition: 0.3s all;
  width: 15rem;
  height: 15rem;
  overflow: hidden;
  border-radius: 50%;
  border: 5px solid transparent;
  box-shadow: 0 0 0 3px #fff;
}

.startup-img img {
  -webkit-transition: 0.3s all;
  -moz-transition: 0.3s all;
  transition: 0.3s all;
  pointer-events: none;
}

.startup-img:hover img {
  transform: scale(1.1);
}

/* Slider DesignStudio Navigation */

#rev_slider_8_1 .tparrows {
  background: transparent;
  width: 44px;
}

#rev_slider_8_1 .tparrows:hover {
  background: transparent;
}

#rev_slider_8_1 .tparrows.tp-leftarrow:before,
#rev_slider_8_1 .tparrows.tp-rightarrow:before {
  display: none;
}

#rev_slider_8_1 .tparrows.tp-leftarrow {
  border-left: 3px solid;
  border-color: #24c1dd;
  padding-left: 4px;
}

#rev_slider_8_1 .tparrows.tp-rightarrow {
  border-right: 3px solid;
  border-color: #24c1dd;
}

#rev_slider_8_1 .tp-leftarrow .hvr-pulse {
  height: 8px;
}

#rev_slider_8_1 .tp-rightarrow .hvr-pulse {
  height: 8px;
}

#rev_slider_8_1 .tp-leftarrow .hvr-pulse:before {
  content: url("/images/leftarrow.png");
}

#rev_slider_8_1 .tp-rightarrow .hvr-pulse:before {
  content: url("/images/rightarrow.png");
}

/* Pulse */

@-webkit-keyframes hvr-pulse {
  25% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  75% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@keyframes hvr-pulse {
  25% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  75% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

.hvr-pulse {
  display: inline-block;
  vertical-align: bottom;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

.hvr-pulse:hover,
.hvr-pulse:focus,
.hvr-pulse:active {
  -webkit-animation-name: hvr-pulse;
  animation-name: hvr-pulse;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

#rev_slider_8_1_wrapper .tp-caption span {
  color: #24c1dd;
}

.bg-summer-sky {
  background-color: #24c1dd;
}

.color-summer-sky {
  color: #24c1dd;
}

.hephaistos .tp-bullet:hover,
.hephaistos .tp-bullet.selected {
  border-color: #38c172 !important;
}

.hephaistos .tp-bullet.selected {
  cursor: default !important;
}

.hephaistos .tp-bullet {
  background: rgba(36, 205, 213, 0.6);
  cursor: pointer;
}

.hephaistos .tp-bullet {
  width: 12px;
  height: 12px;
  position: absolute;
  background: #999;
  border: 3px solid #f5f5f5;
  border-radius: 50%;
  cursor: pointer;
  box-sizing: content-box;
  box-shadow: 0px 0px 2px 1px rgba(130, 130, 130, 0.3);
}

.hephaistos .tp-bullet:hover,
.hephaistos .tp-bullet.selected {
  background: #fff;
  border-color: #000;
}

.hephaistos .tp-bullet {
  background: rgba(36, 205, 213, 0.6);
  cursor: pointer;
}

/*index creative agency end*/

/*video section*/

.video-play-button {
  height: 70px;
  width: 70px;
  background: #38c172;
  display: inline-block;
  top: 50%;
  right: 0;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  line-height: 74px;
  text-align: center;
  padding-left: 4px;
  color: #ffffff;
  -webkit-box-shadow: 0 0 0 0 white;
  box-shadow: 0 0 0 0 white;
  z-index: 1;
  -webkit-transform: translate(50%, -40%);
  -ms-transform: translate(50%, -40%);
  -o-transform: translate(50%, -40%);
  transform: translate(50%, -40%);
}

.video-play-button.slider-play-button {
  margin: 10px;
  -webkit-transform: translate(0%, 0%);
  -ms-transform: translate(0%, 0%);
  -o-transform: translate(0%, 0%);
  transform: translate(0%, 0%);
}

.video-play-button:hover,
.video-play-button:focus {
  -webkit-animation: pulse_effect 1s infinite;
  -o-animation: pulse_effect 1s infinite;
  animation: pulse_effect 1s infinite;
  background: #ffffff;
  color: #38c172;
}

.video-play-button i {
  font-size: 20px;
}

/* video button two */

.video-play-button-small {
  height: 50px;
  width: 50px;
  background: #ffffff;
  display: inline-block;
  border-radius: 50px;
  line-height: 57px;
  text-align: center;
  color: #38c172;
  -webkit-box-shadow: 0 0 0 0 white;
  box-shadow: 0 0 0 0 white;
  z-index: 1;
}

.video-play-button-small:hover,
.video-play-button-small:focus {
  -webkit-animation: pulse_effect 1s infinite;
  -o-animation: pulse_effect 1s infinite;
  animation: pulse_effect 1s infinite;
  background: #ffffff;
  color: #38c172;
}

.video-play-button-small i {
  font-size: 20px;
}

@-webkit-keyframes pulse_effect {
  0% {
    -webkit-box-shadow: 0 0 0 5px rgba(36, 205, 213, 0.9);
    box-shadow: 0 0 0 5px rgba(36, 205, 213, 0.9);
  }

  25% {
    -webkit-box-shadow: 0 0 0 10px rgba(36, 205, 213, 0.6);
    box-shadow: 0 0 0 10px rgba(36, 205, 213, 0.6);
  }

  70% {
    -webkit-box-shadow: 0 0 0 15px rgba(36, 205, 213, 0.3);
    box-shadow: 0 0 0 15px rgba(36, 205, 213, 0.3);
  }

  100% {
    -webkit-box-shadow: 0 0 0 60px rgba(36, 205, 213, 0.1);
    box-shadow: 0 0 0 20px rgba(36, 205, 213, 0.1);
  }
}

@-o-keyframes pulse_effect {
  0% {
    box-shadow: 0 0 0 5px rgba(36, 205, 213, 0.9);
  }

  25% {
    box-shadow: 0 0 0 10px rgba(36, 205, 213, 0.6);
  }

  70% {
    box-shadow: 0 0 0 15px rgba(36, 205, 213, 0.3);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(36, 205, 213, 0.1);
  }
}

@keyframes pulse_effect {
  0% {
    -webkit-box-shadow: 0 0 0 3px rgba(36, 205, 213, 0.9);
    box-shadow: 0 0 0 3px rgba(36, 205, 213, 0.9);
  }

  25% {
    -webkit-box-shadow: 0 0 0 6px rgba(36, 205, 213, 0.6);
    box-shadow: 0 0 0 6px rgba(36, 205, 213, 0.6);
  }

  70% {
    -webkit-box-shadow: 0 0 0 9px rgba(36, 205, 213, 0.3);
    box-shadow: 0 0 0 9px rgba(36, 205, 213, 0.3);
  }

  100% {
    -webkit-box-shadow: 0 0 0 12px rgba(36, 205, 213, 0.1);
    box-shadow: 0 0 0 12px rgba(36, 205, 213, 0.1);
  }
}

.split-blog-scale img {
  transform: scale(1);
  transition: 1s;
}

.split-blog-scale:hover img {
  transform: scale(1.15);
}

.video-content-setting {
  min-height: 260px;
}

.video-content-col {
  max-width: 450px;
  margin-left: auto;
  margin-right: 100px;
}

/*video section ends*/

/* ----- Social Icons ----- */

.social-icons-simple {
  margin-top: -3px;
}

ul.social-icons-simple li,
ul.social-icons li {
  display: inline-block;
}

ul.social-icons li a,
ul.social-icons-simple li a {
  height: 30px;
  line-height: 30px;
  width: 30px;
  font-size: 14px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  margin: 0 1px;
  text-align: center;
  display: block;
  color: #26313c;
  position: relative;
  z-index: 1;
  border: 1px solid #7c7c7c;
}

ul.no-border li a {
  border: none !important;
}

ul.social-icons-simple li a {
  border: none;
}

ul.social-icons.whitebg li a {
  background: #fff;
}

ul.social-icons li:last-child a {
  margin-right: 0;
}

ul.social-icons li:first-child a {
  margin-left: 0;
}

ul.social-icons li a::before,
ul.social-icons-simple li a::before {
  content: "";
  position: absolute;
  left: -1px;
  right: -1px;
  bottom: -1px;
  top: -1px;
  opacity: 0;
  visibility: hidden;
  background: #38c172;
  border-radius: inherit;
  z-index: -1;
  -webkit-transform: scale(0.5);
  -moz-transform: scale(0.5);
  -o-transform: scale(0.5);
  transform: scale(0.5);
}

ul.social-icons li a:hover::before,
ul.social-icons li a:focus::before,
ul.social-icons-simple li a:hover::before,
ul.social-icons-simple li a:focus::before {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

ul.social-icons.white li a,
ul.social-icons-simple.white li a,
ul.social-icons li a:hover,
ul.social-icons li a:focus,
ul.social-icons.white li a:hover,
ul.social-icons.white li a:focus {
  color: #fff;
}

/*icon bg bover */

.facebook:before {
  background-color: #4267B2 !important;
}

.twitter:before {
  background-color: #1DA1F2 !important;
}

.insta:before {
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%) !important;
}

.linkedin:before {
  background-color: #0077B5 !important;
}

.whatsapp:before {
  background-color: #09cc16 !important;
}

/*-----Buttons-----*/

.button {
  position: relative;
  display: inline-block;
  font-size: 15px;
  padding: 0.8rem 2.25rem;
  line-height: 1;
  text-transform: capitalize;
  text-align: center;
  font-weight: 500;
  z-index: 1;
  border: 1px solid transparent;
  -webkit-border-radius: 30px;
  border-radius: 30px;
}

.transition-3 {
  -webkit-transition: 0.3s !important;
  -moz-transition: 0.3s !important;
  -o-transition: 0.3s !important;
  transition: 0.3s !important;
}

.no-transition * {
  transition-timing-function: initial;
  -moz-transition-timing-function: initial;
  -webkit-transition-timing-function: initial;
  -o-transition-timing-function: initial;
  transition-duration: 0s;
  -moz-transition-duration: 0s;
  -webkit-transition-duration: 0s;
  -o-transition-duration: 0s;
}

.button-padding {
  padding: 0.8rem 2.25rem !important;
}

.btnsecondary {
  background: #3490dc;
  color: #fff;
}

.btnprimary {
  background: #38c172;
  color: #fff;
}

.gradient-btn {
  -webkit-background-size: 200% auto;
  background-size: 200% auto;
  background-image: -webkit-gradient(linear, left top, right top, from(#38c172), color-stop(51%, #23ced5), to(#38c172));
  background-image: -webkit-linear-gradient(left, #38c172 0%, #23ced5 51%, #38c172 100%);
  background-image: -moz-linear-gradient(left, #38c172 0%, #23ced5 51%, #38c172 100%);
  background-image: -o-linear-gradient(left, #38c172 0%, #23ced5 51%, #38c172 100%);
  background-image: linear-gradient(to right, #38c172 0%, #23ced5 51%, #38c172 100%);
  color: #fff;
}

.gradient-btn:hover {
  background-position: right center;
}

.btnsecondary:hover,
.btnsecondary:focus,
.btnprimary:hover,
.btnprimary:focus,
.gradient-btn:hover,
.gradient-btn:focus {
  color: #fff;
}

.btn-with-shadow:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  left: 0;
  top: 0;
  position: absolute;
  transition: all 0.3s ease;
  opacity: 0;
}

.btn-with-shadow:hover:after {
  opacity: 1;
}

.btnsecondary:hover,
.btnsecondary:focus {
  background-color: #0083ff;
}

.btnsecondary:hover.btn-with-shadow::after {
  box-shadow: 10px 0 30px 0 rgba(0, 131, 255, 0.5);
}

.btnprimary:hover,
.btnprimary:focus {
  background-color: #01a23c;
}

.btnprimary:hover.btn-with-shadow::after {
  box-shadow: 10px 0 30px 0 rgba(1, 162, 60, 0.5);
}

.button-readmore {
  display: inline-block;
  position: relative;
  padding-bottom: 5px;
  font-weight: 500;
}

.btn-dark,
.btn-dark:active {
  background: #2D2D2D !important;
  outline: none;
}

.btn-dark:hover,
.btn-dark:focus {
  border-color: #38c172 !important;
  background: transparent !important;
  color: #38c172 !important;
}

.btn-dark.hover-light:hover {
  color: #fff;
  border-color: #fff !important;
}

.btn-light {
  color: #757575;
  background-color: #f8f9fa;
  border-color: #757575;
}

.btn-light:hover,
.btn-light:focus {
  border-color: #38c172 !important;
  background: transparent !important;
  color: #38c172 !important;
}

/*
.btn-primary,
.btn-primary:active {
    background: $primary !important;
    outline: none;
}

.btn-primary:hover,
.btn-primary:focus {
    border-color: $primary !important;
    background: transparent !important;
    color: $primary !important;
}

.btn-secondary,
.btn-secondary:active {
    background: #6B747C !important;
    outline: none;
}

.btn-secondary:hover,
.btn-secondary:focus {
    border-color: #6B747C !important;
    background: transparent !important;
    color: #6B747C !important;
}
 */

.button-readmore::after {
  content: "";
  height: 2px;
  width: 70%;
  margin-left: 10px;
  display: inline-block;
  -webkit-background-size: 200% auto;
  background-size: 200% auto;
  background-image: -webkit-gradient(linear, left top, right top, from(#38c172), color-stop(51%, #23ced5), to(#38c172));
  background-image: -webkit-linear-gradient(left, #38c172 0%, #23ced5 51%, #38c172 100%);
  background-image: -moz-linear-gradient(left, #38c172 0%, #23ced5 51%, #38c172 100%);
  background-image: -o-linear-gradient(left, #38c172 0%, #23ced5 51%, #38c172 100%);
  background-image: linear-gradient(to right, #38c172 0%, #23ced5 51%, #38c172 100%);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: margin-left 0.2s linear;
  -o-transition: margin-left 0.2s linear;
  transition: margin-left 0.2s linear;
}

.button-readmore:hover::after {
  margin-left: 0;
}

/* transitions common*/

a,
*::before,
*::after,
.button,
.image img,
.navbar,
.navbar .dropdown-menu,
.team-content h4,
.overlay,
.overlay ul,
.card-header-none-style .card-link:before,
.contact-table .icon-cell,
.feature-item .icon,
.cbp-l-filters .cbp-filter-item span,
.testi-box .testimonial-round > img,
.feature-item .icon,
#services-slider .service-box > span {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

i::before,
i::after {
  -webkit-transition: all 0s !important;
  -o-transition: all 0s !important;
  transition: all 0s !important;
}

.side-menu .btn-close::before,
.side-menu .btn-close::after,
.process-wrapp li > .pro-step::before,
.process-wrapp li::before,
.process-wrapp li::after,
.half-section-alt::before,
.team-content::before,
.progress-bars .progress .progress-bar span::after,
.bg-testimonial::after,
ul.rounded li::before,
.webcats li a::before,
ul.hours_links li::before,
.side-nav .navbar-nav .nav-link::after,
.numbered .tp-count:before,
.owl-prev::before,
.owl-next::before,
.contact-banner::before {
  content: "";
}

.bg-white {
  background-color: #fff;
}

.dropdown-toggle::after,
.card-header-none-style .card-link:before,
blockquote.blockquote::before,
ul.links li a::before,
.form-check label::before {
  font-family: "Font Awesome 5 Free", serif;
  font-weight: 900;
}

.dropdown-toggle::after {
  transition: all 0s !important;
}

/* ----- Navigation Starts ----- */

.navbar {
  padding: 0.35rem 0;
  z-index: 1000;
}

.transparent-bg {
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
}

.fixed-bottom {
  position: absolute;
  background: #fff;
  padding: 0;
  -webkit-box-shadow: 0 10px 25px -15px rgba(0, 0, 0, 0.35);
  box-shadow: 0 10px 25px -15px rgba(0, 0, 0, 0.35);
}

.fixed-bottom.fixedmenu {
  bottom: auto;
  top: 0;
  -webkit-animation: none;
  -o-animation: none;
  animation: none;
}

.fixed-bottom.menu-top .dropdown-toggle::after {
  content: "";
}

.fixedmenu {
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  margin: 0 !important;
  padding: 0;
  z-index: 1002;
  background: #fff !important;
  -webkit-box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.35);
  box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.35);
  -webkit-animation-name: animationFade;
  -o-animation-name: animationFade;
  animation-name: animationFade;
  -webkit-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fixedmenu.no-animation {
  animation: none;
  animation-duration: 0.1s;
}

.sub-footer {
  padding-bottom: 35px;
  text-align: center;
  position: relative;
}

.sub-footer span {
  font-size: 14px;
  margin-bottom: 1px;
  color: #6987ab;
  display: block;
}

.sub-footer a {
  color: #4b5d73;
}

.sub-footer a:hover {
  color: #38c172;
}

.sub-footer .sub-footer__link {
  color: #38c172;
  font-weight: 700;
}

@-webkit-keyframes animationFade {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@-o-keyframes animationFade {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    -o-transform: none;
    transform: none;
  }
}

@keyframes animationFade {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    -o-transform: none;
    transform: none;
  }
}

/**/

.scroll-down {
  display: inline-block;
  position: absolute;
  bottom: 30px;
  z-index: 13;
  left: 50%;
  transform: translateX(-50%);
  user-select: none;
}

.scroll-down i {
  font-size: 12px;
  padding-left: 10px;
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(2px);
  }

  100% {
    transform: translateY(-2px);
  }
}

.navbar-brand {
  margin: 16px 0;
  padding: 0;
  width: 120px;
  position: relative;
}

.navbar-brand.center-brand {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 0;
  z-index: 1;
}

.center-brand + .navbar-collapse {
  margin: 3px 0;
}

.navbar-brand > img {
  width: 100%;
}

.navbar.fixedmenu .logo-default,
.navbar .logo-scrolled {
  display: none;
}

.navbar.fixedmenu .logo-scrolled,
.navbar .logo-default {
  display: inline-block;
}

.navbar-nav .nav-item {
  position: relative;
}

.navbar.sidebar-nav .navbar-nav .nav-item {
  margin: 10px 0;
}

.navbar-nav .nav-item:first-child {
  margin-left: 0;
}

.navbar-nav .nav-item:last-child {
  margin-right: 0;
}

.process-wrapp li > .pro-step::before .navbar-nav .nav-link {
  padding: 8px 0 !important;
  font-weight: 500;
  font-size: 14px;
  color: #26313c;
  position: relative;
  line-height: normal;
  margin: 16px 1.25rem;
  display: inline-block;
}

.navbar-nav .nav-item:last-child .nav-link {
  margin-right: 0;
}

.nav-item.dropdown:hover {
  cursor: pointer;
}

.text-warning-hvr:hover,
.text-warning-hvr:focus {
  color: #ffc107;
}

.text-grey {
  color: #a5a5a5;
}

.navbar-nav .nav-link.dropdown-toggle::before {
  display: none;
}

.transparent-bg .navbar-nav .nav-link {
  color: #fff;
}

.static-nav .navbar-nav .nav-link:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 3px;
  width: 0;
  height: 2px;
  background: #38c172 !important;
  margin: auto;
  -webkit-transition: 0.4s all;
  -moz-transition: 0.4s all;
  -o-transition: 0.4s all;
  transition: 0.4s all;
}

.transparent-bg.darkcolor .navbar-nav .nav-link {
  color: #26313c;
}

.whitecolor .navbar-nav .nav-link {
  color: #fff;
}

.navbar-nav .nav-link.active:before {
  width: 100%;
}

.dark-slider h2,
.light-slider h2 {
  font-size: 48px !important;
}

.dark-slider p,
.light-slider p {
  font-size: 18px !important;
  line-height: 1.2 !important;
}

.dark-slider h2,
.dark-slider p {
  color: #373737;
}

.light-slider h2,
.light-slider p {
  color: #fff;
}

/*.transparent-bg .navbar-nav .nav-link::before {*/

/*  background: #fff;*/

/*}*/

.fixedmenu .navbar-nav .nav-link {
  color: #26313c;
}

/*DropDowns*/

.collapse {
  position: relative;
}

.sideNavPages {
  /*border-left: 1px solid #ffffff66;*/
  /*margin-top: 4px;*/
}

.sideNavPagesInner {
  margin-left: 30px;
}

.sideNavPagesInner .nav-link {
  font-size: 1.2rem !important;
}

.collapsePagesSideMenu {
  display: flex !important;
}

.collapsePagesSideMenu .fas {
  font-size: 12px;
  margin: auto 10px auto auto;
}

.sideNavPages .nav-item {
  display: block;
  margin: 5px 0;
}

.sideNavPages .nav-link {
  font-size: 1.335rem !important;
  margin-left: 30px !important;
}

.static.dropdown {
  position: static;
}

.dropdown-toggle::after {
  content: "";
  border: none;
  vertical-align: baseline;
  width: auto;
  height: auto;
  line-height: 1;
}

.megamenu {
  /*padding: 20px 0;*/
  width: 100%;
}

.navbar-nav .dropdown-menu {
  z-index: 5;
  background: #fff;
  border: none;
  border-radius: 0;
  padding: 18px 0;
  -webkit-box-shadow: 0 13px 25px -12px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 13px 25px -12px rgba(0, 0, 0, 0.25);
  box-shadow: 0 13px 25px -12px rgba(0, 0, 0, 0.25);
  left: 0;
  top: auto;
  border-top: 1px solid #efefef;
  margin-top: 0;
  cursor: default;
}

.menu-top .navbar-nav .dropdown-menu {
  -webkit-box-shadow: 0 0 25px -10px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 0 25px -10px rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 25px -10px rgba(0, 0, 0, 0.25);
  bottom: 68px;
}

.dropdown-menu.right-show {
  left: auto !important;
  right: 0 !important;
}

.dropdown-menu .dropdown-item {
  background: transparent;
  font-weight: normal;
  font-size: 16px;
  padding: 0 0 0 1.4rem;
  line-height: 40px;
}

a.dropdown-toggle::after {
  transition: all 0s;
}

.dropdown-menu .dropdown-item,
.dropdown-menu .dropdown-title {
  color: #26313c;
}

.dropdown-item.active {
  color: #38c172;
}

.navbar-nav .nav-link:hover,
.navbar .dropdown:hover .dropdown-toggle,
.dropdown-menu .dropdown-item:hover,
.dropdown-menu.megamenu .dropdown-item:hover {
  color: #38c172 !important;
}

.dropdown-menu .dropdown-title {
  margin: 1.5rem 0 0.5rem 1rem;
  font-size: 19px;
}

.hover-light:hover {
  color: #fff !important;
}

.hover-underline:hover span {
  text-decoration: underline;
}

.services-main:hover img {
  transform: scale(1.08);
}

.services-main:hover h3 {
  background-image: -webkit-gradient(linear, left top, right top, from(#38c172), color-stop(51%, #23ced5), to(#38c172));
  background-clip: border-box;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="@colorPrimary", endColorstr="@colorSecondary", GradientType=1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media screen and (min-width: 768px) {
  .service-info-box-title {
    min-height: 60px;
  }
}

@media screen and (min-width: 992px) {
  .navbar-nav .dropdown {
    position: static;
  }

  .navbar .dropdown-menu {
    display: block;
    opacity: 0.4;
    visibility: hidden;
    min-width: 260px;
    transform: scale(1, 0);
    transform-origin: 0 0;
  }

  .navbar.menu-top .dropdown-menu {
    transform-origin: 100% 100%;
  }

  .dropright .dropdown-menu {
    display: block;
    opacity: 0.4;
    visibility: hidden;
    transform: scale(0, 1);
    transform-origin: 0 0;
  }

  .navbar-nav .dropdown:hover > .dropdown-menu,
  .dropright:hover > .dropdown-menu {
    opacity: 1;
    visibility: visible;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 1.5rem !important;
  padding-left: 0.5rem !important;
}

/*-----Side Menu----*/

/*side open btn*/

.sidemenu_btn {
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  width: 36px;
  padding: 6px;
  margin-right: 1rem;
  margin-top: 1px;
  right: 20px;
}

.sidemenu_btn,
.side-menu .btn-close,
.side-menu .btn-close::before,
.side-menu .btn-close::after {
  position: absolute;
}

#close_side_menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  display: none;
  z-index: 1031;
  opacity: 0.5;
  cursor: pointer;
}

.sidemenu_btn > span {
  height: 2px;
  width: 100%;
  display: block;
}

.transparent-bg .sidemenu_btn > span {
  background: #fff;
}

.fixedmenu .sidemenu_btn > span:not(.gradient-bg) {
  background: #26313c !important;
}

.sidemenu_btn > span:nth-child(2) {
  margin: 4px 0 4px auto;
  width: 75%;
  -webkit-transition: 0.3s all;
  -moz-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
}

.sidemenu_btn > span:nth-child(3) {
  margin-left: auto;
  width: 45%;
  -webkit-transition: 0.6s all;
  -moz-transition: 0.6s all;
  -o-transition: 0.6s all;
  transition: 0.6s all;
}

.sidemenu_btn:hover > span:nth-child(2),
.sidemenu_btn:hover > span:nth-child(3) {
  width: 100%;
}

/*side clode btn*/

.side-menu .btn-close {
  height: 33px;
  width: 33px;
  -webkit-box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.15);
  box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 50%;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  position: absolute;
  margin-right: 1.5rem;
  margin-top: 0;
  right: 20px;
  top: 24px;
  z-index: 12;
  cursor: pointer;
}

.side-menu .btn-close.btn-close-no-padding {
  top: 24px;
}

.side-menu .btn-close::before,
.side-menu .btn-close::after {
  left: 16px;
  height: 24px;
  width: 2px;
  background: #fff;
  top: 5px;
}

.side-menu .btn-close:before {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.side-menu .btn-close:after {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.side-menu {
  width: 40%;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1610;
  height: 100vh;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  -webkit-transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out, -o-transform 0.5s ease-in-out;
  -o-transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out, -o-transform 0.5s ease-in-out;
  -moz-transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out, -o-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out, -o-transform 0.5s ease-in-out;
  overflow: hidden;
}

.side-menu:not(.gradient-bg) {
  background: #38c172;
}

.side-menu.side-menu-active {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.side-menu .navbar-brand {
  margin: 0 0 2.5rem 0;
}

.side-menu .inner-wrapper {
  padding: 4.5rem 4rem;
  height: 100%;
  position: relative;
  overflow-y: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: left;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.side-menu .side-nav {
  margin-bottom: 30px;
  display: block;
}

.side-nav .navbar-nav .nav-item {
  display: block;
  margin: 8px 0;
  padding: 0 !important;
  opacity: 0;
  -webkit-transition: all 0.8s ease 500ms;
  -o-transition: all 0.8s ease 500ms;
  transition: all 0.8s ease 500ms;
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  -o-transform: translateY(30px);
  transform: translateY(30px);
}

.side-nav .navbar-nav .nav-item:first-child {
  -webkit-transition-delay: 0.1s;
  -o-transition-delay: 0.1s;
  transition-delay: 0.1s;
  margin-top: 0;
}

.side-nav .navbar-nav .nav-item:nth-child(2) {
  -webkit-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.side-nav .navbar-nav .nav-item:nth-child(3) {
  -webkit-transition-delay: 0.3s;
  -o-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.side-nav .navbar-nav .nav-item:nth-child(4) {
  -webkit-transition-delay: 0.4s;
  -o-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

.side-nav .navbar-nav .nav-item:nth-child(5) {
  -webkit-transition-delay: 0.5s;
  -o-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

.side-nav .navbar-nav .nav-item:nth-child(6) {
  -webkit-transition-delay: 0.6s;
  -o-transition-delay: 0.6s;
  transition-delay: 0.6s;
}

.side-nav .navbar-nav .nav-item:nth-child(7) {
  -webkit-transition-delay: 0.7s;
  -o-transition-delay: 0.7s;
  transition-delay: 0.7s;
}

.side-nav .navbar-nav .nav-item:nth-child(8) {
  -webkit-transition-delay: 0.8s;
  -o-transition-delay: 0.8s;
  transition-delay: 0.8s;
}

.side-nav .navbar-nav .nav-item:nth-child(9) {
  -webkit-transition-delay: 0.9s;
  -o-transition-delay: 0.9s;
  transition-delay: 0.9s;
}

.side-nav .navbar-nav .nav-item:last-child {
  margin-bottom: 0;
}

.side-menu.side-menu-active .side-nav .navbar-nav .nav-item {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}

.side-nav .navbar-nav .nav-link {
  display: inline-table;
  color: #fff !important;
  padding: 5px 0 !important;
  margin: 0;
  font-size: 1.5rem;
  font-weight: 300;
}

.side-nav .navbar-nav .nav-link::before,
.breadcrumb .breadcrumb-item a::before {
  content: "";
  position: absolute;
  background: #fff;
  display: inline-block;
  width: 0;
  height: 3px;
  bottom: 0;
  left: 0;
  overflow: hidden;
  -webkit-transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
  -o-transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
  transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
}

.breadcrumb .breadcrumb-item a::before {
  bottom: -5px;
}

.side-nav .navbar-nav .nav-link:hover::before,
.breadcrumb .breadcrumb-item a:hover::before {
  width: 100%;
}

.side-nav .navbar-nav .nav-link.collapsePagesSideMenu:hover::before {
  width: 0;
}

.transparent-sidemenu .side-nav .navbar-nav .nav-link::before {
  display: none;
}

.side-nav .navbar-nav .nav-link.active::before {
  width: 100%;
}

.transparent-sidemenu .side-nav .navbar-nav .nav-link:hover {
  margin-left: 5px;
}

.side-menu p {
  font-size: 13px;
  margin-top: 0.5rem;
  margin-bottom: 0;
}

/*Header Social Icons*/

.navbar .social-icons li a {
  height: 26px;
  width: 26px;
  line-height: 26px;
  font-size: 14px;
}

.sidebar-nav .social-icons {
  position: absolute;
  bottom: 30px;
}

.navbar.fixedmenu .social-icons.white li a {
  color: #26313c;
}

.navbar.fixedmenu .social-icons.white li a:hover,
.side-menu .social-icons-simple li a:hover {
  color: #fff;
}

@media (max-width: 1200px) {
  .side-menu .inner-wrapper {
    padding: 4rem 3.25rem;
  }

  .process-wrapp li::before,
  .process-wrapp li::after {
    width: 26% !important;
  }

  .video-content-col {
    margin-right: 15px;
  }

  #app-slider,
  .app-slider-lock {
    left: 25px !important;
  }
}

@media (max-width: 1055px) {
  .navbar .social-icons {
    margin-right: 30px;
  }
}

@media (max-width: 1024px) {
  .side-menu .inner-wrapper {
    padding: 4rem 2.7rem;
  }

  .side-nav .navbar-nav .nav-link {
    font-size: 1.25rem;
  }

  .sideNavPages .nav-link {
    font-size: 1.125rem !important;
  }

  .sideNavPagesInner .nav-link {
    font-size: 1rem !important;
  }

  .contactus-wrapp.position-absolute {
    width: 96% !important;
  }
}

@media (max-width: 992px) {
  .transparent-bg:not(.fixedmenu) {
    background: rgba(38, 49, 60, 0.55);
    padding: 0;
  }

  .side-menu {
    width: 50%;
  }

  .side-menu .inner-wrapper {
    padding: 2rem 2.5rem;
  }

  .side-nav .navbar-nav .nav-link {
    font-size: 1.125rem;
  }

  .sideNavPages .nav-link {
    font-size: 1rem !important;
  }

  .side-menu .btn-close {
    top: 33px;
    margin-right: 1.2rem;
    right: 15px;
  }

  nav.side-nav {
    margin-top: 35px;
  }

  .process-wrapp li::before {
    width: 0 !important;
  }

  .process-wrapp li::after {
    width: 70% !important;
    left: 195px;
  }

  .process-wrapp li:nth-child(even)::after,
  .process-wrapp li:last-child::after {
    width: 0 !important;
  }

  .video-content-col {
    max-width: 350px;
    margin-right: 0;
  }
}

@media (max-width: 767px) {
  .process-wrapp .pro-step {
    display: none !important;
  }

  .tp-caption {
    font-size: x-large !important;
  }

  .side-menu .btn-close {
    top: 26px;
  }

  .side-menu {
    width: 80% !important;
  }

  .dropdown-menu .dropdown-title {
    display: none;
  }

  .bottom15 .icon {
    float: left;
  }

  .process-wrapp li::after,
  .process-wrapp li::before {
    width: 0 !important;
  }

  .carousel-shop-detail .owl-nav button:focus {
    background: #ffffff;
    color: #38c172;
  }

  .coming-soon-heading {
    font-size: 70px;
  }

  ul.count_down li {
    padding: 0 20px !important;
  }

  ul.count_down li p:nth-child(1) {
    font-size: 50px !important;
  }

  .video-content-col {
    max-width: 450px;
    margin-right: auto;
    margin-left: auto;
  }

  #blog .news_item {
    margin-bottom: 30px;
  }

  .contactus-wrapp.position-absolute {
    width: 94% !important;
    top: -220px !important;
  }
}

@media (max-width: 576px) {
  .tp-caption {
    font-size: larger !important;
  }

  .side-menu .inner-wrapper {
    padding: 2rem 5rem;
  }

  .side-menu {
    width: 100% !important;
  }

  .navbar-nav {
    margin-top: 1.5rem;
  }

  .contactus-wrapp.position-absolute {
    top: -300px !important;
  }

  .full-map {
    height: 540px !important;
  }
}

/*---- Navigation Ends ----*/

.part-header {
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  height: 100vh;
  background-image: url("/images/bg-part-header.jpg");
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -2;
}

#particles-js {
  height: 100vh;
}

#particles-text-morph {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  user-select: none;
  /*pointer-events: none;*/
  text-align: center;
}

/*---- OWl / Revolution ----*/

/*Dots*/

.owl-dots {
  margin-top: 2rem;
  text-align: center;
}

.owl-dots .owl-dot {
  height: 3px;
  width: 20px;
  background: #26313c;
  margin: 2px 3px;
  border: 1px solid #26313c;
  display: inline-block;
  cursor: pointer;
  padding: 0;
}

.no-dots .owl-dots {
  display: none;
}

.owl-dots .owl-dot,
.numbered .tp-bullet,
.numbered .tp-bullet-number,
.numbered .tp-bullet .tp-count {
  position: relative;
}

.numbered .tp-bullet {
  width: auto;
  height: 15px;
  background: transparent;
  cursor: pointer;
  color: #18191d;
  transition: 3s height;
}

.numbered .tp-bullet-number {
  text-align: left;
}

.numbered .tp-bullet-number,
.numbered .tp-bullet .tp-count {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.numbered .tp-bullet.selected:nth-child(4) {
  padding-bottom: 4px;
  border-bottom: 2px solid;
}

.numbered,
.tp-tabs,
.tp-thumbs {
  top: 47% !important;
}

.numbered .tp-bullet .tp-count {
  font-size: 14px;
  bottom: 5px;
}

.numbered .tp-count:before {
  height: 3px;
  width: 40px;
  transform: scaleX(0);
  display: block;
  background: #38c172;
  top: 100%;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.numbered .tp-count:before,
.revicon {
  position: absolute;
}

.numbered .tp-bullet.selected .tp-count {
  color: #38c172;
}

.white .numbered .tp-bullet .tp-count,
.white .numbered .tp-count:before,
.white .numbered .tp-bullet.selected .tp-count {
  color: #fff;
}

.white .numbered .tp-count:before {
  background: #fff;
}

.numbered .tp-bullet.selected .tp-count:before {
  transform: scaleX(1);
  visibility: visible;
  opacity: 1;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.animate-out {
  -webkit-animation-delay: 0ms;
  -o-animation-delay: 0ms;
  animation-delay: 0ms;
}

.revicon {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 40px;
  z-index: 11;
}

.revicon li a {
  height: 40px;
}

/*Buttons*/

.owl-prev,
.owl-next {
  background: transparent;
  border: none;
  color: #26313c;
  height: 30px;
  width: 30px;
  font-size: 16px;
  line-height: 29px;
}

.gray-body .owl-nav button,
.gray-body .back-top {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.owl-prev,
.owl-prev::before,
.owl-next,
.owl-next::before {
  -webkit-border-radius: 50%;
  border-radius: 50%;
  position: absolute;
}

.owl-prev::before,
.owl-next::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(0.5);
  -moz-transform: scale(0.5);
  -o-transform: scale(0.5);
  transform: scale(0.5);
  -webkit-background-size: 200% auto;
  background-size: 200% auto;
  background-image: -webkit-gradient(linear, left top, right top, from(#38c172), color-stop(51%, #23ced5), to(#38c172));
  background-image: -webkit-linear-gradient(left, #38c172 0%, #23ced5 51%, #38c172 100%);
  background-image: -moz-linear-gradient(left, #38c172 0%, #23ced5 51%, #38c172 100%);
  background-image: -o-linear-gradient(left, #38c172 0%, #23ced5 51%, #38c172 100%);
  background-image: linear-gradient(to right, #38c172 0%, #23ced5 51%, #38c172 100%);
}

.owl-prev:hover,
.owl-next:hover {
  color: #fff;
}

.owl-prev:hover::before,
.owl-next:hover::before {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.owl-prev {
  left: 0;
}

.owl-next {
  right: 0;
}

/*----- OWl / Revolution ends-----*/

/*-----Page Headers----- */

.page-header {
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
}

.single-items {
  -webkit-background-size: cover !important;
  background-size: cover !important;
}

#para-menu li a.active,
#para-menu li a:hover {
  color: #fff !important;
}

#para-menu li a {
  position: relative;
}

#para-menu li a:before {
  content: "";
  position: absolute;
  background: #fff;
  display: inline-block;
  width: 0;
  height: 3px;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

#para-menu li a.current:before {
  width: 100%;
}

#pp-nav {
  display: none;
  visibility: hidden;
  opacity: 0;
}

.para-btn.disabled {
  opacity: 0.5;
  cursor: default;
}

.para-btn {
  font-size: 2.5rem;
  color: #fff;
  cursor: pointer;
}

.fixed-para-btn {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 20;
  width: 50px;
}

.fixed-para-btn .para-btn {
  line-height: 1;
  transition: 0.3s all;
}

.fixed-para-btn .para-btn svg {
  transition: 0.3s all;
}

.fixed-para-btn .para-btn.disabled svg:hover {
  fill: #fff;
}

.fixed-para-btn .para-btn svg:hover {
  fill: #38c172;
}

.dark-gradient {
  background: rgba(0, 0, 0, 0.4);
}

.bg-parallax-1 {
  background: url("/images/bg-single-1.jpg") no-repeat;
}

.bg-parallax-2 {
  background: url("/images/bg-single-2.jpg") no-repeat;
}

.bg-parallax-3 {
  background: url("/images/bg-single-3.jpg") no-repeat;
}

.bg-parallax-4 {
  background: url("/images/bg-single-4.jpg") no-repeat;
}

.bg-parallax-5 {
  background: url("/images/bg-single-5.jpg") no-repeat;
}

.bg-parallax-6 {
  background: url("/images/bg-single-6.jpg") no-repeat;
}

.bg-parallax-7 {
  background: url("/images/bg-single-7.jpg") no-repeat;
}

.about-header {
  background: url("/images/bg-about-header.jpg") no-repeat;
}

.startup-header {
  background: url("/images/banner9.jpg") no-repeat;
}

.parallax-header {
  background: url("/images/bg-parallax-header.jpg") no-repeat;
  background-size: cover !important;
}

.blog-header {
  background: url("/images/bg-blog-header.jpg") no-repeat;
}

.blog2-header {
  background: url("/images/bg-blog2-header.jpg") no-repeat;
}

.blog-detail-header {
  background: url("/images/bg-blog-detail-header.jpg") no-repeat;
}

.service-header {
  background: url("/images/bg-service-header.jpg") no-repeat;
}

.service-detail-header {
  background: url("/images/bg-service-detail-header.jpg") no-repeat;
}

.contact-header {
  background: url("/images/bg-contact-header.jpg") no-repeat;
}

.gallery-header {
  background: url("/images/bg-gallery-header.jpg") no-repeat;
}

.gallery-detail-header {
  background: url("/images/bg-gallery-detail-header.jpg") no-repeat;
}

.shop-detail-header {
  background: url("/images/bg-shop-detail-header.jpg") no-repeat;
}

.shop-header {
  background: url("/images/bg-shop-header.jpg") no-repeat;
}

.cart-header {
  background: url("/images/bg-cart-header.jpg") no-repeat;
}

.team-header {
  background: url("/images/bg-team-header.jpg") no-repeat;
}

.pricing-header {
  background: url("/images/bg-pricing-header.jpg") no-repeat;
}

.testimonial-header {
  background: url("/images/bg-testimonial-header.jpg") no-repeat;
}

.faq-header {
  background: url("/images/bg-faq-header.jpg") no-repeat;
}

.error404-header {
  background: url("/images/bg-404-header.jpg") no-repeat;
}

.coming-soon-header {
  background: url("/images/bg-coming-soon-header.jpg") no-repeat;
}

.sign-in-header {
  background: url("/images/bg-sign-in-header.jpg") no-repeat;
}

.sign-up-header {
  background: url("/images/bg-sign-up-header.jpg") no-repeat;
}

.reset-header {
  background: url("/images/bg-reset-header.jpg") no-repeat;
}

.testimonial-bg {
  background: url("/images/testimonial-bg.jpg") no-repeat;
}

.contact-banner::before,
.contact-banner iframe {
  position: absolute;
}

.contact-banner::before {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1;
}

.page-header .page-titles,
.page-header .title-wrap {
  position: relative;
}

.page-header .page-titles {
  z-index: 5;
}

.title-wrap h3 {
  margin-top: 3px;
}

.page-header .title-wrap {
  padding: 1.125rem 1.5rem;
  -webkit-transform: translateY(50%);
  -moz-transform: translateY(50%);
  -ms-transform: translateY(50%);
  -o-transform: translateY(50%);
  transform: translateY(50%);
  line-height: 1;
  z-index: 2;
  margin-bottom: -24px;
}

.breadcrumb {
  background: transparent;
  padding: 0;
}

.breadcrumb .breadcrumb-item {
  font-size: 14px;
  position: relative;
}

.breadcrumb-item + .breadcrumb-item::before {
  color: inherit;
}

.contact-banner iframe {
  height: 100%;
  width: 100%;
}

iframe {
  border: none;
}

/*-----Page Headers -----*/

/*-----Aboutus----- */

.aboutus h2 {
  font-size: 2.25rem;
}

#services-slider,
#services-slider .service-box,
.services-main .services-content,
.process-wrapp,
.process-wrapp li,
.process-wrapp li > .pro-step,
.feature-item .icon,
.feature-item .text h3,
.half-section-alt,
.bg-counters,
.progress-bars .progress,
.progress-bars .progress p {
  position: relative;
}

.half-section-alt::before,
.progress-bars .progress .progress-bar,
.progress-bars .progress .progress-bar span,
.progress-bars .progress .progress-bar span::after,
.process-wrapp li > .pro-step::before,
.process-wrapp li::before,
.process-wrapp li::after {
  position: absolute;
}

#services-slider .service-box > span,
.process-wrapp li > .pro-step,
.progress-bars .progress p,
.progress-bars .progress .progress-bar span::after {
  display: inline-block;
}

/*Service Slider*/

#services-slider {
  margin: -140px 0 0;
  min-height: 345px;
  z-index: 21;
}

#services-slider.half-margin {
  margin: -110px 0 0;
}

#services-slider.owl-carousel .owl-stage-outer {
  padding-top: 25px;
  padding-bottom: 25px;
}

#services-slider .service-box {
  padding: 3rem 1.5rem;
  background: #26313c;
  color: #fff;
  text-align: center;
  -webkit-transition: all 0.5s ease 0.1s;
  -o-transition: all 0.5s ease 0.1s;
  transition: all 0.5s ease 0.1s;
  z-index: 2;
}

#services-slider.services-slider-light .service-box {
  background: #556772;
}

.bg-light-dark {
  background: #556772;
}

#services-slider .service-box,
#services-slider .service-box > span {
  overflow: hidden;
}

#services-slider .service-box > span {
  font-size: 40px;
  height: 50px;
  width: 50px;
  line-height: 50px;
}

#services-slider .service-box h4 {
  font-weight: bold;
}

#services-slider .service-box i {
  -webkit-transform: scale(0.85);
  -moz-transform: scale(0.85);
  -ms-transform: scale(0.85);
  -o-transform: scale(0.85);
  transform: scale(0.85);
  -webkit-transition: all 0.18s linear;
  -o-transition: all 0.18s linear;
  transition: all 0.18s linear;
}

#services-slider .service-box:hover i {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

#services-slider .owl-item.center .service-box {
  -webkit-background-size: 200% auto;
  background-size: 200% auto;
  background-image: -webkit-gradient(linear, left top, right top, from(#38c172), color-stop(51%, #23ced5), to(#38c172));
  background-image: -webkit-linear-gradient(left, #38c172 0%, #23ced5 51%, #38c172 100%);
  background-image: -moz-linear-gradient(left, #38c172 0%, #23ced5 51%, #38c172 100%);
  background-image: -o-linear-gradient(left, #38c172 0%, #23ced5 51%, #38c172 100%);
  background-image: linear-gradient(to right, #38c172 0%, #23ced5 51%, #38c172 100%);
  padding: 4.5rem 1.5rem;
  margin-top: -25px;
}

.gradient-bg {
  -webkit-background-size: 200% auto;
  background-size: 200% auto;
  background-image: -webkit-gradient(linear, left top, right top, from(#38c172), color-stop(51%, #23ced5), to(#38c172));
  background-image: -webkit-linear-gradient(left, #38c172 0%, #23ced5 51%, #38c172 100%);
  background-image: -moz-linear-gradient(left, #38c172 0%, #23ced5 51%, #38c172 100%);
  background-image: -o-linear-gradient(left, #38c172 0%, #23ced5 51%, #38c172 100%);
  background-image: linear-gradient(to right, #38c172 0%, #23ced5 51%, #38c172 100%);
}

#services-slider .owl-item.center .service-box:hover {
  background-position: right center;
}

/*Services main*/

.services-main .services-content {
  margin-left: 10px;
  margin-right: 10px;
  padding: 2rem 1rem;
  margin-top: -30px;
  background: #ffffff;
  z-index: 5;
}

/*Services ends*/

/* Work Process */

.process-wrapp {
  overflow: hidden;
  display: table;
  table-layout: fixed;
  margin: 0 15px;
}

.process-wrapp,
.process-wrapp li,
.process-wrapp li > .pro-step {
  z-index: 1;
}

.process-wrapp li,
.process-wrapp li > .pro-step {
  text-align: center;
}

.process-wrapp li {
  padding: 70px 15px 0 15px;
  display: table-cell;
  max-width: 229px;
  vertical-align: top;
}

/*
.process-wrapp li:hover {
    cursor: pointer;
}
*/

.process-wrapp li > .pro-step {
  height: 90px;
  width: 90px;
  line-height: 90px;
  border: 1px solid #fff;
  font-size: 28px;
  font-weight: 600;
  color: #fff;
}

.process-wrapp li > .pro-step,
.process-wrapp li > .pro-step::before {
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.process-wrapp.no-rounded li > .pro-step,
.process-wrapp.no-rounded li > .pro-step::before {
  -webkit-border-radius: 0 !important;
  border-radius: 0 !important;
}

.process-wrapp li > .pro-step::before {
  background: -webkit-gradient(linear, left top, right top, from(#38c172), to(#23ced5));
  background: -webkit-linear-gradient(left, #38c172, #23ced5);
  background: -o-linear-gradient(left, #38c172, #23ced5);
  background: linear-gradient(90deg, #38c172, #23ced5);
  opacity: 0;
  visibility: hidden;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

.process-wrapp li p:first-child {
  font-size: 15px;
}

.process-wrapp li p:last-child {
  font-size: 13px;
  margin-bottom: 4px;
}

/*
.process-wrapp li:hover > .pro-step {
    border-color: transparent;
    color: #fff;
}
*/

.process-wrapp li.active > .pro-step {
  border-color: transparent;
  color: #fff;
}

/*
.process-wrapp li.active:hover > .pro-step {
    border-color: transparent !important;
}

.process-wrapp li:hover > .pro-step::before {
    opacity: 1;
    visibility: visible;
    border-radius: 50%;
}
*/

.process-wrapp li.active > .pro-step::before {
  opacity: 1;
  visibility: visible;
  border-radius: 50%;
}

/*
.process-wrapp li.active:hover > .pro-step::before {
    opacity: 1 !important;
    visibility: visible !important;
    border-radius: 50%;
}

.process-wrapp:hover li.active > .pro-step::before {
    opacity: 0;
    visibility: hidden;
}

.process-wrapp:hover li.active > .pro-step {
    border-color: #fff;
}
*/

.process-wrapp li::before,
.process-wrapp li::after {
  height: 1px;
  top: 115px;
  background: rgba(255, 255, 255, 0.5);
  width: 30%;
  z-index: -1;
  margin: 0;
}

.process-wrapp li:first-child::before,
.process-wrapp li:last-child::after {
  opacity: 0;
  visibility: hidden;
}

.process-wrapp li::before {
  left: 0;
}

.process-wrapp li::after {
  right: 0;
}

.rotate-180 {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  transform: rotate(180deg);
}

.collapsePagesSideMenu i {
  transition: all 0.3s linear;
}

/*Work Process ends */

/*App Features*/

#app-feature .feature-item:hover,
.feature-item.image {
  cursor: pointer;
}

.feature-item.innovative-border {
  border: 1px solid #38c172;
  padding: 15px 10px 0 10px;
  position: relative;
}

.feature-item.innovative-border.arr-left::before {
  content: "";
  position: absolute;
  width: 14px;
  height: 14px;
  border-right: 1px solid #38c172;
  border-bottom: 1px solid #38c172;
  background: #fff;
  top: calc(50% - 7px);
  right: -8px;
  transform: rotate(-45deg);
}

.feature-item.innovative-border.arr-right::before {
  content: "";
  position: absolute;
  width: 14px;
  height: 14px;
  border-left: 1px solid #38c172;
  border-top: 1px solid #38c172;
  background: #fff;
  top: calc(50% - 7px);
  left: -8px;
  transform: rotate(-45deg);
}

.feature-item img,
.feature-item .icon {
  display: inline-flex;
}

.feature-item.image {
  max-width: 250px;
  margin-top: 35px;
}

.feature-item h4 {
  font-size: 1.5rem;
}

.feature-item .icon {
  z-index: 1;
  font-size: 38px;
  margin-bottom: 20px;
  color: #38c172;
  vertical-align: top;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.feature-item .text h3 {
  width: 100%;
}

.feature-item:hover .text h3 span {
  background-color: #38c172;
  background-clip: border-box;
  background-size: 200%, 100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.feature-item:hover .icon {
  transform: translateY(-6px);
}

.feature-item.innovative-border:hover .icon {
  transform: scale(1.1);
}

.feature-item.active {
  background: #f7f7f7;
}

.image.mobile-apps > img {
  opacity: 0;
}

.image.mobile-apps.active > img {
  opacity: 1;
}

.app-image {
  text-align: center;
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.app-image > img {
  max-width: 280px;
  margin: 0 auto;
  display: inline-block;
  pointer-events: none;
  position: inherit;
  z-index: 100;
}

#app-slider,
.app-slider-lock {
  position: absolute;
  top: 14px;
  width: 235px;
  left: 58px;
  height: 475px;
  z-index: 1;
}

#app-slider {
  cursor: w-resize;
}

.app-slider-lock {
  z-index: 2;
  display: block;
}

.app-slider-lock img {
  width: 100%;
}

.app-slider-lock-btn {
  position: absolute;
  bottom: 0;
  opacity: 0;
  width: 40px;
  height: 40px;
  border: 3px solid #0b0b0b;
  cursor: pointer !important;
  border-radius: 50%;
  background-color: transparent;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.app-image:hover .app-slider-lock-btn {
  bottom: 30px;
  opacity: 1;
}

.app-slider-lock-btn:hover {
  transform: translateX(-50%) scale(1.1);
}

/*half sections*/

.half-section-alt::before {
  height: 60%;
  left: 0;
  bottom: 0;
  width: 100%;
  background: #f6f7f8;
}

/*App Features ends*/

/* Counters */

.bg-counters {
  background: url("/images/counter-bg.jpg") no-repeat center center;
  -webkit-background-size: cover;
  background-size: cover;
}

.counters .count_nums {
  font-size: 6.25rem;
  line-height: 5rem;
}

.counters .count_nums,
.counters i {
  vertical-align: middle;
}

/*Counters ends */

/* Progress Bars */

.progress-bars .progress .progress-bar:not(.gradient-bg),
.progress-bars .progress .progress-bar span:not(.gradient-bg) {
  background: #000;
}

.progress-bars .progress {
  overflow: visible;
  height: 18px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  opacity: 0;
  background: #eee;
}

.progress-bars .progress + .progress {
  margin-top: 50px;
}

.progress-bars .progress p {
  padding-bottom: 10px;
  left: 0;
}

.progress-bars .progress p,
.progress-bars .progress .progress-bar span {
  top: -28px;
}

.progress-bars .progress .progress-bar {
  float: none;
  height: 100%;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -webkit-transition: width 1s ease-in-out 0.3s;
  -o-transition: width 1s ease-in-out 0.3s;
  transition: width 1s ease-in-out 0.3s;
}

.progress-bars .progress .progress-bar span {
  display: block;
  right: 0;
  width: 34px;
  padding: 0;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  font-size: 10px;
  line-height: 1.7;
  text-align: center;
}

.progress-bars .progress .progress-bar span::after {
  height: 8px;
  width: 8px;
  background: inherit;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  left: 50%;
  margin-left: -4px;
  bottom: -4px;
  z-index: -1;
}

/*Progress Bars */

/*Faq Accordions*/

#accordion .card {
  margin-bottom: 15px;
}

#accordion .card:last-child {
  margin-bottom: 0;
}

.card-header-none-style {
  padding: 0;
  background-color: transparent;
  border-bottom: none;
}

.card-header-none-style .card-link {
  display: block;
  padding: 0.75rem 1.25rem;
  background: #fff;
  border: none;
  font-size: 1.125rem;
  color: #26313c;
}

.card-header-none-style .card-link:before {
  content: "";
  float: right;
}

.card-header-none-style .card-link.collapsed:before {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  transform: rotate(180deg);
}

.card-body {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 1.85rem;
  padding-bottom: 1.35rem;
}

.card-body p:last-child {
  margin-bottom: 0;
}

/*Accordion ends*/

@media screen and (max-width: 1400px) {
  .counters .count_nums {
    font-size: 5.625rem;
  }
}

@media screen and (max-width: 1266px) {
  .counters .count_nums {
    font-size: 5rem;
  }
}

@media screen and (max-width: 992px) {
  .process-wrapp {
    text-align: center;
  }

  .process-wrapp li {
    display: inline-block;
    max-width: 300px;
  }

  .process-wrapp li:first-child::before,
  .process-wrapp li:last-child::after {
    opacity: 1;
    visibility: visible;
  }

  #app-feature .feature-item {
    max-width: 300px;
    display: inline-block;
  }

  .app-image img {
    max-width: 100%;
  }

  #app-slider,
  .app-slider-lock {
    position: absolute;
    top: 10px;
    width: 195px;
    left: 10px !important;
    height: 400px;
  }

  .app-slider-lock-btn {
    width: 35px;
    height: 35px;
  }
}

@media screen and (max-width: 767px) {
  #services-slider {
    margin: -70px 0 0;
  }

  #services-slider.half-margin {
    margin: -55px 0 0;
  }

  .app-image img {
    max-width: 100%;
  }

  #app-slider,
  .app-slider-lock {
    top: 8px;
    width: 155px;
    left: 8px;
    overflow: hidden;
  }

  .app-image {
    width: 170px;
    margin: 3rem auto;
  }
}

/*-----Aboutus ends -----*/

/*----- Team -----*/

.team-content {
  padding: 2rem 1rem;
  background: #fff;
  text-align: center;
  height: 108px;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.equal-shadow-team .team-content {
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
}

.team-content::before {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  -ms-transform: translateY(100%);
  -o-transform: translateY(100%);
  transform: translateY(100%);
  background-size: 200% auto;
  background-image: -webkit-gradient(linear, left top, right top, from(#38c172), color-stop(51%, #23ced5), to(#38c172));
  background-image: -webkit-linear-gradient(left, #38c172 0%, #23ced5 51%, #38c172 100%);
  background-image: -moz-linear-gradient(left, #38c172 0%, #23ced5 51%, #38c172 100%);
  background-image: -o-linear-gradient(left, #38c172 0%, #23ced5 51%, #38c172 100%);
  background-image: linear-gradient(to right, #38c172 0%, #23ced5 51%, #38c172 100%);
}

.team-content p {
  margin-bottom: 0;
  font-size: 13px;
}

.team-box:hover .team-content * {
  color: #fff;
}

.team-box:hover .team-content h4 {
  margin-top: -15px;
}

.team-box:hover .team-content::before {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

.team-content ul {
  -webkit-transform: translateY(150%);
  -moz-transform: translateY(150%);
  -ms-transform: translateY(150%);
  -o-transform: translateY(150%);
  transform: translateY(150%);
}

.team-box:hover .team-content ul {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  margin-top: 5px;
}

.team-box:hover img {
  -webkit-transform: scale(1.2);
  -o-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}

.overlay {
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  background-image: -webkit-linear-gradient(to right, #23ced5aa 0%, #38c172 100%);
  background-image: -moz-linear-gradient(to right, #23ced5aa 0%, #38c172 100%);
  background-image: -ms-linear-gradient(to right, #23ced5aa 0%, #38c172 100%);
  background-image: -o-linear-gradient(to right, #23ced5aa 0%, #38c172 100%);
  background-image: linear-gradient(to right, #23ced5aa 0%, #38c172 100%);
  position: absolute;
  opacity: 0;
  visibility: hidden;
  z-index: 99;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

.overlay-blue {
  background-image: -webkit-linear-gradient(to right, #23ced5 0%, #38c172 100%);
  background-image: -moz-linear-gradient(to right, #23ced5 0%, #38c172 100%);
  background-image: -ms-linear-gradient(to right, #23ced5 0%, #38c172 100%);
  background-image: -o-linear-gradient(to right, #23ced5 0%, #38c172 100%);
  background-image: linear-gradient(to right, #23ced5 0%, #38c172 100%);
  visibility: visible;
}

.overlay-dark {
  background-image: none;
  background-color: #000;
  z-index: 1;
  visibility: visible;
}

.overlay-gray {
  background-image: none;
  background-color: #333333;
  z-index: 1;
  visibility: visible;
}

.half-section-alt.teams-border::before {
  height: 108px;
}

#our-team-main.half-section-alt.teams-border::before {
  height: 160px;
}

.bg-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: visible;
}

.bg-black {
  background: #000000;
}

/*----- Team ends -----*/

/*----- Testimonial -----*/

.bg-testimonial,
#testimonial-slider .owl-nav {
  position: relative;
}

#testimonial-slider {
  margin-top: -90px;
  margin-bottom: -20px;
}

.bg-transparent {
  background: transparent;
}

.bg-testimonial::after {
  height: 55%;
  left: 0;
  top: 0;
  position: absolute;
  background: url("/images/testimonial-bg.jpg") no-repeat center center;
  -webkit-background-size: cover;
  background-size: cover;
  z-index: -1;
}

.bg-testimonial::after,
.testi-box .testimonial-round > img {
  width: 100%;
}

.testi-box .testimonial-round {
  height: 180px;
  width: 180px;
  overflow: hidden;
}

.testi-box .testimonial-round,
.testi-box .testimonial-round > img {
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.testi-box.no-rounded .testimonial-round,
.testi-box.no-rounded .testimonial-round > img {
  -webkit-border-radius: 0 !important;
  border-radius: 0 !important;
  border: 1px solid #38c172;
}

.testi-box .testimonial-round:hover > img {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
}

.testimonial-round > img {
  pointer-events: none;
}

#testimonial-slider .owl-nav {
  width: 300px;
  display: inline-block;
  height: 30px;
  -webkit-transform: translateY(-150%);
  -moz-transform: translateY(-150%);
  -ms-transform: translateY(-150%);
  -o-transform: translateY(-150%);
  transform: translateY(-150%);
  margin-left: 2%;
  position: absolute;
  z-index: 5;
}

/*styling for star rating*/

.test-star {
  font-size: 12px;
  color: #ffc13c;
  cursor: pointer;
}

#rattingIcon .fa-star {
  color: #ffc107;
  font-size: 16px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

#ratingText {
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

@media (max-width: 992px) {
  #testimonial-slider .owl-nav {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    margin-left: 1%;
  }
}

/*----- Testimonial ends home page-----*/

/* ----- Testimonials page start ----- */

.testimonial-wrapp {
  padding-bottom: 0.25rem;
  margin: auto 1rem;
}

.testimonial-wrapp,
.testimonial-wrapp .testimonial-text,
.testimonial-wrapp .testimonial-photo,
.testimonial-wrapp .quoted,
.testimonial-quote {
  position: relative;
  -webkit-transition: all 0.4s ease-in;
  -o-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
}

.testimonial-wrapp,
.testimonial-wrapp .testimonial-text {
  text-align: center;
}

.testimonial-wrapp .quoted,
.testimonial-wrapp .testimonial-photo {
  display: inline-block;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.testimonial-wrapp .quoted {
  -webkit-background-size: 100% 100%;
  background-size: 200% 100%;
  background-image: -webkit-gradient(linear, left top, right top, from(#38c172), color-stop(51%, #23ced5), to(#38c172));
  background-image: -webkit-linear-gradient(left, #38c172 0%, #23ced5 51%, #38c172 100%);
  background-image: -moz-linear-gradient(left, #38c172 0%, #23ced5 51%, #38c172 100%);
  background-image: -o-linear-gradient(left, #38c172 0%, #23ced5 51%, #38c172 100%);
  background-image: linear-gradient(to right, #38c172 0%, #23ced5 51%, #38c172 100%);
  color: #fff;
  font-size: 20px;
  line-height: 50px;
  height: 50px;
  width: 50px;
  -webkit-transform: translateY(50%);
  -ms-transform: translateY(50%);
  -o-transform: translateY(50%);
  transform: translateY(50%);
  z-index: 1;
}

.testimonial-wrapp .testimonial-text {
  padding: 4.25rem 2.5rem;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  border: 1px solid #f1f1f1;
  overflow: hidden;
  text-align: justify;
  transition: color 0.3s ease-in 0.1s;
}

#testimonial-grid .testimonial-wrapp .testimonial-text {
  padding: 3rem 0.75rem;
}

.testimonial-wrapp .testimonial-photo {
  height: 100px;
  width: 100px;
  margin-top: -50px;
  overflow: hidden;
}

.testimonial-wrapp .testimonial-photo,
.testimonial-wrapp .testimonial-photo > img {
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.testimonial-wrapp:hover img {
  transform: scale(1.1) rotate(-10deg);
}

h4.darkcolor {
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.testimonial-wrapp .testimonial-text::before {
  content: "";
  position: absolute;
  top: -10%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: 30px;
  width: 30px;
  -webkit-background-size: 200% auto;
  background-size: 200% auto;
  background-image: -webkit-gradient(linear, left top, right top, from(#38c172), color-stop(51%, #23ced5), to(#38c172));
  background-image: -webkit-linear-gradient(left, #38c172 0%, #23ced5 51%, #38c172 100%);
  background-image: -moz-linear-gradient(left, #38c172 0%, #23ced5 51%, #38c172 100%);
  background-image: -o-linear-gradient(left, #38c172 0%, #23ced5 51%, #38c172 100%);
  background-image: linear-gradient(to right, #38c172 0%, #23ced5 51%, #38c172 100%);
  -webkit-border-radius: 50%;
  border-radius: 50%;
  z-index: -2;
  transition: top 0.4s linear, transform 0.6s ease 0.2s;
}

.testimonial-wrapp:hover .testimonial-text::before {
  top: 60%;
  transform: translate(-50%, -50%) scale(20);
}

.testimonial-icon {
  overflow: hidden;
}

.testimonial-icon .social-icons {
  transform: translateY(-200%) scale(1.7);
  opacity: 0;
  transition: 0.5s ease-out 0.1s;
}

.testimonial-wrapp:hover .testimonial-icon .social-icons {
  transform: translateY(0) scale(1);
  opacity: 1;
}

.testimonial-wrapp:hover .testimonial-text,
.testimonial-wrapp:hover .quoted {
  color: #fff;
}

#testimonial-main-slider .owl-dot,
#price-slider .owl-dot,
#ourteam-main-slider .owl-dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: transparent;
  transition: 0.4s all;
  border-color: #a5a5a5;
  overflow: hidden;
}

#testimonial-main-slider .owl-dot::before,
#price-slider .owl-dot::before,
#ourteam-main-slider .owl-dot::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #38c172;
  transform: translateY(100%);
}

#testimonial-main-slider .active.owl-dot::before,
#price-slider .active.owl-dot::before,
#ourteam-main-slider .active.owl-dot::before {
  transform: translateY(0);
}

/* ----- Testimonial page ends ----- */

/*----- Gallery Portfolio -----*/

/*filters*/

.cbp-l-filters .cbp-filter-item {
  cursor: pointer;
  margin: 0 0.2rem;
}

.cbp-l-filters .cbp-filter-item,
.cbp-l-filters .cbp-filter-item span {
  display: inline-block;
}

.cbp-l-filters .cbp-filter-item span {
  color: #26313c;
  font-size: 0.85rem;
  text-transform: capitalize;
  position: relative;
  padding-bottom: 0;
}

.cbp-l-filters > .cbp-filter-item + .cbp-filter-item::before {
  display: inline-block;
  padding-right: 0.6rem;
  padding-left: 5px;
  color: inherit;
  content: "/";
}

.cbp-l-filters.whitecolor .cbp-filter-item span {
  color: #fff;
}

.cbp-l-filters .cbp-filter-item-active {
  cursor: default;
}

.cbp-l-filters .cbp-filter-item-active span,
.cbp-l-filters .cbp-filter-item:hover span,
.cbp-l-filters .cbp-filter-item:focus span,
.cbp-l-filters.whitecolor .cbp-filter-item:focus span,
.cbp-l-filters.whitecolor .cbp-filter-item-active span {
  color: #38c172;
}

.gallery-hvr {
  width: 100%;
  height: 100%;
  background: #38c172;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: scale(2);
  -moz-transform: scale(2);
  -ms-transform: scale(2);
  -o-transform: scale(2);
  transform: scale(2);
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.cbp-item:hover .gallery-hvr {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.cbp-item img {
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  max-width: 200px !important;
  max-height: 200px !important;
  padding: 30px 0 30px 0;
}

.cbp-item:hover img {
  transform: scale(1.1);
}

.gallery-hvr .opens {
  display: inline-block;
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  margin-right: 5px;
  margin-left: 5px;
  font-size: 18px;
  border: 1px solid #fff;
  -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  background: #fff;
  color: #26313c;
  margin-bottom: 15px;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.gallery-hvr .opens::before,
.shopping-box .overlay .opens::before {
  content: "";
  position: absolute;
  left: -1px;
  right: -1px;
  bottom: -1px;
  top: -1px;
  opacity: 0.3;
  visibility: hidden;
  background: #38c172;
  border-radius: inherit;
  z-index: -1;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}

.gallery-hvr .opens:hover::before,
.gallery-hvr .opens:focus::before,
.shopping-box .overlay .opens:hover::before,
.shopping-box .overlay .opens:focus::before {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.gallery-hvr .opens:hover,
.gallery-hvr .opens:focus {
  color: #fff;
}

.gallery-hvr .center-box {
  position: absolute;
  left: 50%;
  top: 50%;
  text-align: center;
  width: 100%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.center-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cbp-l-loadMore-stop {
  display: none !important;
  opacity: 0;
  visibility: hidden;
}

.cbp-l-loadMore-button .cbp-l-loadMore-link {
  color: #fff !important;
  padding: 2px 35px;
}

.fa-spin {
  -webkit-animation: fa-spin 0.75s infinite linear !important;
  animation: fa-spin 0.75s infinite linear !important;
  font-size: 15px;
}

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.fancybox-thumbs__list a:before {
  border-color: #38c172;
}

/*----- Gallery ends -----*/

/* ----- Partner ----- */

#partners-slider .item {
  text-align: center;
}

#partners-slider .item,
#partners-slider .logo-item {
  position: relative;
}

#partners-slider .logo-item {
  width: 160px;
  height: 60px;
  overflow: hidden;
}

#partners-slider .logo-item,
#partners-slider .logo-item > img {
  display: inline-block;
}

.logo-item > img {
  width: 98%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.logo-footer {
  max-width: 300%;
}

/* ----- Partner ends ----- */

/*----- Contact US -----*/

section#stayconnect {
  margin-top: -40px;
}

.contactus-wrapp,
.getin_form .form-control,
#map-container,
.form-check,
.form-group label,
.checkbox label {
  position: relative;
}

.contactus-wrapp {
  padding: 2.5rem 2rem;
  -webkit-transform: translateY(40px);
  -moz-transform: translateY(40px);
  -ms-transform: translateY(40px);
  -o-transform: translateY(40px);
  transform: translateY(40px);
  z-index: 5;
}

.contactus-wrapp.position-absolute {
  width: 97%;
  top: -180px;
}

.single-page-footer {
  margin-top: 0;
}

.single-page-footer .contactus-wrapp {
  transform: translateY(0px);
  background: #F1F1F1;
}

.contactus-wrapp,
.getin_form .form-control,
.form-check label::before,
.form-check input[type=checkbox]:checked + label::before {
  background: #fff;
}

.getin_form .form-control {
  border: 1px solid #ececec;
  -webkit-box-shadow: none;
  box-shadow: none;
  height: 41px;
  color: #b6b8ba;
  font-size: 0.875rem;
}

/*transparent form parallax version*/

.transparent-form input,
.transparent-form textarea {
  background: transparent;
  color: #fff !important;
  padding: 1.5rem 0.75rem;
  border-radius: 30px;
  border-color: #9ca2a8;
}

.transparent-form input:focus,
.transparent-form textarea:focus {
  background: transparent;
  border-color: #fff;
}

.transparent-form textarea {
  padding-top: 0.75rem;
  height: 150px;
}

/*CheckBox*/

.form-check {
  display: block;
  font-size: 13px;
}

.form-group label,
.checkbox label {
  font-size: 14px;
  font-weight: 500;
  padding-left: 10px;
  min-height: 20px;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.form-group label,
.checkbox label,
.form-check label::before,
.contact-table .icon-cell,
span.icon-contact {
  display: inline-block;
}

.form-check label::before {
  content: "";
  position: absolute;
  width: 17px;
  height: 17px;
  left: 0;
  margin-left: -20px;
  border: 1px solid #ececec;
  -webkit-border-radius: 0;
  border-radius: 0;
  text-align: center;
  color: transparent;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}

.form-check input[type=checkbox]:checked + label::before {
  background: #38c172;
  border-color: #38c172;
  color: #fff;
}

textarea {
  min-height: 160px;
  resize: none;
}

button {
  border: none;
  cursor: pointer;
}

/* Form general- */

::-webkit-input-placeholder,
.form-control::-webkit-input-placeholder,
:-moz-placeholder,
.form-control:-moz-placeholder,
::-moz-placeholder,
.form-control::-moz-placeholder,
:-ms-input-placeholder,
.form-control:-ms-input-placeholder {
  color: #b6b8ba;
}

textarea::-webkit-input-placeholder,
textarea:-moz-placeholder,
textarea::-moz-placeholder,
textarea:-ms-input-placeholder {
  color: #b6b8ba;
  opacity: 1;
}

.contact-table .cells {
  vertical-align: middle;
}

.contact-table .icon-cell {
  color: #26313c;
  text-align: center;
  font-size: 18px;
  margin-right: 10px;
}

#map-container,
#map-container > iframe {
  width: 100%;
}

#map-container > iframe {
  border: none;
  height: 100%;
}

span.icon-contact {
  font-size: 30px;
  margin-bottom: 20px;
  line-height: 1;
}

.noshadow .widget {
  box-shadow: none !important;
}

/*----- Contact US -----*/

.contact-wrapper .widget .icon-contact {
  -webkit-transition: 0.3s all;
  -moz-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
}

.contact-wrapper .widget:hover .icon-contact {
  transform: scale(1.2);
}

.contact-wrapper .widget i {
  -webkit-transition: 0.3s all;
  -moz-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
  transition-delay: 0.3s;
}

.contact-wrapper .widget:hover i {
  transform: rotateY(-180deg);
}

/* ----- Our Blog ----- */

.news_item {
  background: #fff;
}

.news_item.shadow {
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1) !important;
}

.shadow-equal {
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.news_desc p {
  margin-bottom: 0;
}

#our-blog .news_desc p {
  margin-bottom: 35px;
}

#our-blog .news_item {
  margin-bottom: 30px;
}

.news_item .news_desc {
  padding: 40px 30px;
}

#our-blog .cbp-item:hover img {
  transform: scale(1.15);
}

.news_item:hover .image img {
  transform: scale(1.05);
}

.news_item,
.eny_profile,
blockquote.blockquote,
.widget_search .input-group-addon,
.eny_profile .profile_text {
  position: relative;
}

.meta-tags li,
.meta-tags li a,
blockquote.blockquote::before,
ul.rounded li::before,
.webtags li,
.webcats li a::before,
.webcats li a > span {
  display: inline-block;
}

.single_post span,
.webcats li,
.webcats li a,
.webtags li a,
.btn_reply {
  display: block;
}

.meta-tags > li + li {
  margin-left: 10px;
}

.meta-tags li a {
  color: #a5a5a5;
  font-size: 12px;
  font-weight: 600;
}

.meta-tags li a > i {
  font-size: 17px;
  margin-right: 5px;
}

.meta-tags li a > i,
.meta-tags > li + li::before,
.webcats li a::before,
.single_post .text {
  vertical-align: text-bottom;
}

ul.rounded li::before {
  vertical-align: unset;
}

.news_item:hover h3 {
  color: #38c172;
}

.meta-tags li a:hover,
.meta-tags li a:focus,
.commment li a:hover,
.commment li a:focus {
  color: #38c172;
}

.news_item .blog-img {
  position: relative;
  margin: 0 auto;
}

.owl-blog-item .owl-nav {
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.owl-blog-item .owl-nav .owl-next,
.owl-blog-item .owl-nav .owl-prev {
  font-size: 18px;
  margin: 5px;
  padding: 3px 30px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  background: transparent;
  border: 2px solid #fff;
  color: #fff;
  position: unset;
  display: inline-flex;
  justify-content: center;
  -webkit-transition: 0.2s all;
  -moz-transition: 0.2s all;
  -o-transition: 0.2s all;
  transition: 0.2s all;
}

.owl-blog-item .owl-nav .owl-prev {
  margin-left: auto;
}

.owl-blog-item .owl-nav .owl-prev:before,
.owl-blog-item .owl-nav .owl-next:before {
  visibility: hidden;
  opacity: 0;
  display: none;
}

.owl-blog-item .owl-nav .owl-next:hover,
.owl-blog-item .owl-nav .owl-prev:hover {
  background: #38c172;
  border: 2px solid #38c172;
}

.play-hvr {
  position: absolute;
  z-index: 8;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  transition: 0.3s all;
}

.play-icon {
  position: relative;
  height: 60px;
  width: 60px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  border: 2px solid transparent;
  background: #38c172;
  padding-left: 4px;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
  transition: 0.3s;
}

.play-hvr:hover .play-icon {
  color: #38c172;
  background: #fff;
  border-color: #38c172;
}

/* ------- 404 ------ */

#error .error {
  position: relative;
}

#error .error h1 {
  position: relative;
  color: #ebebeb;
  font-size: 10rem;
}

#error .error h2 {
  font-size: 2rem;
  left: 50%;
  top: 50%;
  position: absolute;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  animation: moveUpDown404 1s infinite linear;
}

@keyframes moveUpDown404 {
  0% {
    transform: translate(-50%, -50%);
  }

  25% {
    transform: translate(-50%, -60%);
  }

  50% {
    transform: translate(-50%, -50%);
  }

  75% {
    transform: translate(-50%, -40%);
  }

  100% {
    transform: translate(-50%, -50%);
  }
}

/* 404 */

/*Cart table*/

.cart_table table {
  margin-bottom: 0;
}

.border-radius {
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.border-radius-50 {
  -webkit-border-radius: 50px;
  border-radius: 50px;
}

.classic-border {
  border: 2px solid #fff;
}

.cart_table .table-responsive thead {
  background: #f9f9f9;
}

.cart_table .table-responsive thead tr > th {
  padding: 15px 20px;
  border-bottom-width: 1px;
}

.cart_table .table-responsive tbody tr > td {
  vertical-align: middle;
  padding: 20px;
}

.cart_table .product-name {
  display: inline-block;
  vertical-align: middle;
}

.cart_table .btn-close {
  display: inline-block;
  color: #e84455;
  font-size: 20px;
}

.cart_table .shopping-product {
  display: inline-block;
  height: 70px;
  width: 70px;
  margin-right: 10px;
}

.cart_table .shopping-product > img {
  width: 100%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.cart_table .d-table:hover .product-name,
.cart_table .d-table:focus .product-name {
  color: #38c172;
}

.apply_coupon {
  border: 1px solid #d1d1d1;
  border-top: none;
  padding: 20px;
}

.apply_coupon .coupon > form input {
  height: 42px;
  margin-bottom: 0;
  margin-right: 5px;
}

.apply_coupon .coupon > form .form-group > input {
  width: 100%;
}

.apply_coupon .coupon a {
  width: 48%;
}

.totals {
  padding: 40px 15px;
  border: 1px solid #d1d1d1;
}

.totals .table-responsive tr > td {
  border: none;
  padding: 17px 10px;
}

.totals form.findus .form-control {
  margin-bottom: 15px;
  padding-left: 15px;
  border-color: #d2d2d2;
}

.cart-total tbody tr td {
  width: 100% !important;
}

.totals form.findus .select:after {
  color: #A5A5A5;
}

@media (max-width: 768px) {
  .apply_coupon .btn_common {
    font-size: 10px;
    padding: 10px 1rem;
  }
}

/*Shop page*/

.shopping-box {
  -webkit-box-shadow: 0 0 10px #d1d1d1;
  box-shadow: 0 0 10px #d1d1d1;
  overflow: hidden;
}

.shopping-box .shop-content {
  padding: 20px 15px;
}

.shopping-box:hover .overlay .opens {
  -ms-transform: rotate(0) scale(1);
  -o-transform: rotate(0) scale(1);
  -webkit-transform: rotate(0) scale(1);
  transform: rotate(0) scale(1);
}

.shopping-box .price-product {
  font-family: "Open Sans", sans-serif;
  background-image: -webkit-gradient(linear, left top, right top, from(#38c172), color-stop(51%, #23ced5), to(#38c172));
  background-clip: border-box;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="@colorPrimary", endColorstr="@colorSecondary", GradientType=1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.overlay .opens {
  height: 70px;
  width: 70px;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  border: 1px solid #fff;
  display: inline-block;
  background: #fff;
  font-size: 28px;
  text-align: center;
  line-height: 70px;
  opacity: 1;
  -ms-transform: rotate(50deg) scale(1.5);
  -o-transform: rotate(50deg) scale(1.5);
  -webkit-transform: rotate(50deg) scale(1.5);
  transform: rotate(50deg) scale(1.5);
  z-index: 1;
  overflow: hidden;
  position: relative;
}

.overlay .opens i {
  color: #000000aa;
  transition: all 0.3s;
  line-height: 1;
}

.overlay .opens:hover i,
.overlay .opens:focus i {
  color: #fff;
}

.shopping-box:hover img {
  transform: scale(1.05);
}

.shopping-box:hover .overlay {
  opacity: 1;
  visibility: visible;
}

.shopping-box:hover h4 > a,
.shopping-box:focus h4 > a {
  color: #38c172;
}

.quote .quote {
  border: 1px solid #d1d1d1;
  margin-right: 10px;
  max-width: 80px;
  padding: 8px 10px;
  text-align: center;
  vertical-align: top;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  height: 38px;
  font-size: 12px;
}

.quote .btn-common {
  font-size: 11px;
  padding: 10px 1.25rem;
  border-radius: 30px;
  border: 1px solid transparent;
}

.quote,
.quote a {
  display: inline-block;
}

.heading .divider-left {
  margin: 10px 0 -2px;
}

.heading .divider-center {
  margin: 10px auto;
}

.heading .divider-left,
.heading .divider-center {
  height: 4px;
  width: 70px;
  background-image: -webkit-gradient(linear, left top, right top, from(#38c172), color-stop(51%, #23ced5), to(#38c172));
  display: block;
}

.quote-wrapper h3 {
  position: relative;
  color: #fff;
  font-size: 1.35rem;
  display: inline;
}

.quote-wrapper h3::before {
  content: "";
  font-family: "Font Awesome 5 Free", serif;
  font-weight: 900;
  font-size: 20px;
  position: absolute;
  color: #fff;
  left: -25px;
  top: -10px;
}

.quote-wrapper h3::after {
  content: "";
  font-family: "Font Awesome 5 Free", serif;
  font-weight: 900;
  font-size: 20px;
  position: absolute;
  color: #fff;
  bottom: -10px;
  right: -25px;
}

/*shop detail Tabs*/

.tab-container {
  border: 1px solid #d1d1d1;
  padding: 2.5rem 1rem 0 1rem;
  -webkit-border-radius: 0 0 5px 5px;
  border-radius: 0 0 5px 5px;
}

.tab-container > p {
  margin-bottom: 0;
}

.tab-container .accordion-item + div > p {
  margin-bottom: 1rem;
}

.tabset-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

.tabset-list a {
  display: block;
  padding: 12px 1.75rem;
  color: #fff;
  font-weight: 600;
  -webkit-border-radius: 23px;
  border-radius: 23px;
  line-height: normal;
}

.tabset-list li {
  position: relative;
}

.tabset-list li a {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background: #d1d1d1;
  cursor: pointer;
}

.tabset-list .active > a {
  background: #38c172;
  cursor: default;
}

.tab-container p:last-child {
  margin-bottom: 0;
}

.accordion-item {
  display: none !important;
}

/*shop carousel*/

.carousel-shop-detail .owl-nav,
#carousel-gallery-detail .owl-nav {
  display: flex;
  align-items: center;
}

.carousel-shop-detail,
#carousel-gallery-detail {
  overflow: hidden;
}

.carousel-shop-detail .owl-nav button,
#carousel-gallery-detail .owl-nav button {
  background: #ffffffaa;
  color: #000;
  border-radius: 3px;
  padding: 20px 15px;
  transition: all 0.3s ease-in;
  display: flex;
}

.carousel-shop-detail .owl-nav button:hover,
#carousel-gallery-detail .owl-nav button:hover {
  background: #ffffff;
  color: #38c172;
}

.carousel-shop-detail .owl-nav button:before,
#carousel-gallery-detail .owl-nav button:before {
  transform: scale(0);
  visibility: hidden;
  opacity: 0;
}

.carousel-shop-detail:hover .owl-nav button,
#carousel-gallery-detail:hover .owl-nav button {
  transform: translateX(0);
}

.carousel-shop-detail .owl-nav .owl-prev {
  margin: -50% 15px;
  transform: translateX(-45px);
}

#carousel-gallery-detail .owl-nav .owl-prev {
  margin: -33% 15px;
  transform: translateX(-45px);
}

.carousel-shop-detail .owl-nav .owl-next {
  margin: -50% 15px;
  transform: translateX(45px);
}

#carousel-gallery-detail .owl-nav .owl-next {
  margin: -33% 15px;
  transform: translateX(45px);
}

.carousel-shop-detail .owl-nav button span,
#carousel-gallery-detail .owl-nav button span {
  font-size: 22px;
  font-weight: bold;
  margin: -15px -3px;
}

.carousel-shop-detail-inner .item {
  margin-top: 2px;
  opacity: 0.5;
  border: 2px solid transparent;
  transition: all 0.4s linear;
}

.carousel-shop-detail-inner .synced .item {
  opacity: 1;
  border: 2px solid #38c172;
}

.carousel-shop-detail .owl-stage-outer {
  /*height: 350px;*/
}

#syncCarousel .owl-stage {
  pointer-events: auto;
}

#syncCarousel .item img {
  -webkit-transition: all 0.1s ease-out;
  -moz-transition: all 0.1s ease-out;
  -o-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;
  cursor: zoom-in;
}

#syncCarousel .item:hover img {
  transform: scale(1.8);
}

#syncChild .owl-item:not(.synced) {
  cursor: pointer;
}

#carousel-gallery-detail .item img {
  -webkit-transition: all 0.1s ease-out;
  -moz-transition: all 0.1s ease-out;
  -o-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;
  cursor: zoom-in;
}

#carousel-gallery-detail .item:hover img {
  transform: scale(1.1);
}

/*Comments & Date*/

ul.social_icon li,
.webtags li,
.commment li {
  display: inline-block;
}

.commment li {
  margin-left: 10px;
}

.commment li:first-child {
  margin-left: 0;
}

.commment li:last-child {
  margin-right: 0;
}

.commment li a {
  display: block;
  color: #a5a5a5;
  font-size: 14px;
}

.commment li a > i {
  margin-right: 4px;
  vertical-align: baseline;
}

body:not(.rtl-layout) .news_wrap .news_box .commment,
body:not(.rtl-layout) .readmore {
  direction: ltr;
}

.news_wrap .news_box .commment {
  margin: 20px 0;
}

/*Profile Image*/

.profile_bg {
  background: #f2f2f2;
  -webkit-box-shadow: 0 0 2px #c8c8c8;
  box-shadow: 0 0 2px #c8c8c8;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  padding: 20px;
}

.profile_border {
  border-top: 1px solid #c8c8c8;
}

.profile_border:last-child {
  border-bottom: 1px solid #c8c8c8;
}

.profile {
  background: #fff;
  padding: 15px;
  display: table;
  -webkit-border-radius: 2px;
  border-radius: 2px;
}

.profile .p_pic,
.profile .profile_text {
  display: table-cell;
}

.profile .p_pic {
  width: 100px;
  height: 100px;
  padding-right: 15px;
}

.profile .p_pic img {
  width: 100%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.profile_text {
  vertical-align: top;
  padding-right: 10px;
}

.profile_text h5 {
  margin-bottom: 8px;
}

.profile_text strong {
  color: #b2cb20;
  font-size: 14px;
}

.profile_text span {
  color: #5b5b5b;
  font-size: 13px;
}

/*map */

.full-map {
  height: 400px;
  width: 100%;
  overflow: hidden;
  margin-bottom: 40px;
}

.short-map {
  max-height: 330px;
}

/*Pagination*/

.pagination li > a {
  height: 36px;
  width: 36px;
  padding: 0;
  line-height: 36px;
  font-size: 18px;
  color: #a5a5a5;
  text-align: center;
  margin: 0 4px;
  border: none;
}

.pagination li > a:hover,
.pagination li > a:focus,
.pagination li.active > a {
  background: #38c172 !important;
  color: #fff;
  border: 0;
}

.pagination li > a.disabled {
  pointer-events: none;
}

.pagination li > a,
.pagination li:first-child a,
.pagination li:last-child a,
ul.rounded li::before,
.webcats li a::before,
.eny_profile .profile_photo > img,
.single_post .post > img {
  -webkit-border-radius: 50% !important;
  border-radius: 50% !important;
}

blockquote.blockquote {
  font-style: italic;
}

blockquote.blockquote::before {
  content: "";
  width: 40px;
  font-size: 28px;
  margin-right: 5px;
  color: #a1a1a1;
}

ul.rounded > li + li {
  margin-top: 20px;
}

.rounded li {
  padding-left: 20px;
}

ul.rounded li::before {
  height: 10px;
  width: 10px;
  background: #38c172;
  margin-right: 10px;
  margin-left: -20px;
}

/*Post Comments*/

.eny_profile {
  border-bottom: 1px solid #ececec;
  padding-bottom: 30px;
}

.eny_profile .profile_photo,
.eny_profile .profile_text {
  display: table-cell;
  vertical-align: middle;
}

.eny_profile .profile_photo {
  height: 90px;
  width: 90px;
}

.eny_profile .profile_photo > img {
  width: 100%;
}

.eny_profile .profile_text {
  padding-left: 20px;
}

.eny_profile .profile_text .readmorebtn {
  position: absolute;
  right: 0;
  top: 5px;
}

/*Widget Newlatter or search*/

/*.widget_search .input-group {
   width: 260px;
}*/

.widget_search .form-control {
  /*width: 214px;*/
  /*margin-right: 40px;*/
  -webkit-border-bottom-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
  -webkit-border-top-left-radius: 20px !important;
  border-top-left-radius: 20px !important;
}

.widget_search .form-control,
.widget_search .input-group-addon {
  border-color: #ececec;
  -webkit-box-shadow: none;
  box-shadow: none;
  display: table-cell;
}

.widget_search .input-group-addon {
  background-color: #38c172;
  color: #fff;
  top: 0;
  left: 0;
  font-size: 14px;
  width: 40px;
  -webkit-border-top-right-radius: 20px;
  border-top-right-radius: 20px;
  -webkit-border-bottom-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.widget_search .form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #adaeae;
}

/*review find us form*/

form.findus .form-control {
  height: 48px;
  width: 100%;
  -webkit-box-shadow: none;
  box-shadow: none;
}

form.findus:not(.form-inline) .form-group {
  width: 100%;
}

form.findus .form-control,
form.findus textarea {
  padding: 12px 0;
  margin-bottom: 30px;
  font-size: 14px;
  border: none;
  border-bottom: 1px solid #a5a5a5;
  -webkit-border-radius: 0;
  border-radius: 0;
  position: relative;
  -webkit-transition: border 0.9s ease;
  -o-transition: border 0.9s ease;
  transition: border 0.9s ease;
}

form.findus textarea {
  width: 100%;
  outline: none;
  min-height: 160px;
}

.form-control {
  -webkit-box-shadow: 0 0;
  box-shadow: 0 0;
}

.form-control:focus,
textarea:focus {
  border-color: #38c172;
  -webkit-box-shadow: 0 0;
  box-shadow: 0 0;
}

form.findus .select {
  border: none;
  border-bottom: 1px solid #d1d1d1;
  position: relative;
}

form.findus select {
  height: 100%;
  border: 0;
  width: 100%;
  color: inherit;
  opacity: 0.9;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 12px 15px;
  -webkit-border-radius: inherit;
  border-radius: inherit;
}

form.findus .select:after {
  content: "";
  font-family: "Font Awesome 5 Free", serif;
  font-weight: 900;
  color: inherit;
  font-size: 16px;
  padding: 9px 0 8px 9px;
  position: absolute;
  right: 0;
  top: 0;
  background: transparent;
  z-index: 1;
  text-align: center;
  width: 10%;
  height: 100%;
  pointer-events: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.logincontainer .form-control {
  background: transparent;
}

.logincontainer .btn_common {
  width: 100%;
}

/*web Tags*/

.webtags li {
  margin: 1px;
}

.webtags li a,
.btn_reply {
  -webkit-border-radius: 24px;
  border-radius: 24px;
  color: #6f6f6f;
  font-size: 12px;
  margin: 2px 0;
  padding: 5px 1rem;
  border: 1px solid #ececec;
}

.webtags li a:hover,
.webtags li a:focus {
  color: #fff;
  background: #38c172;
  border: 1px solid #38c172;
}

/*Recent Sidebar*/

.sidebar .widget {
  padding: 30px 15px;
  background: #f6f6f6;
}

.news_box:hover h4 > a,
.news_box:focus h4 > a,
.blog-item:hover h3 > a,
.blog-item:focus h3 > a {
  color: #38c172;
}

.blog-item:hover img {
  transform: scale(1.05);
}

.single_post {
  overflow: hidden;
  display: table;
}

.single_post .post,
.single_post .text {
  display: table-cell;
}

.single_post .post {
  height: 50px;
  width: 50px;
}

.single_post .post > img {
  width: 100%;
}

.single_post .text {
  padding-left: 10px;
  font-size: 14px;
}

.single_post .text > a {
  font-weight: 600;
}

.single_post span {
  font-size: 12px;
}

.single_post:hover a,
.single_post:focus a {
  color: #38c172;
}

/*Categories*/

.webcats > li + li {
  margin-top: 10px;
}

.webcats li a::before {
  height: 8px;
  width: 8px;
  background: #38c172;
  margin-right: 10px;
  vertical-align: baseline;
  margin-bottom: 1px;
}

.webcats li a > span {
  font-weight: 600;
  margin-left: 5px;
  font-size: 0.875rem;
}

.webcats li a:hover,
.webcats li a:focus {
  color: #38c172;
}

.whitebox .widget {
  padding: 40px 30px;
  background: #fff;
  -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
}

.contact-box p {
  margin-bottom: 0;
}

/* ----- Our Blog ends ----- */

/* ----- coming soon ----- */

.count_down p {
  color: #232323;
}

.count_down.animated-gradient p {
  background-image: -webkit-gradient(linear, left top, right top, from(#38c172), color-stop(99%, #23ced5), to(#38c172));
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.count_down.white p {
  color: #ffffff;
}

ul.count_down {
  list-style: none;
  margin: 0 0 0;
  padding: 0;
  display: block;
  text-align: center;
}

ul.count_down li {
  display: inline-block;
  color: #232323;
  padding: 0 35px;
  width: auto;
}

ul.count_down.animated-gradient li {
  padding: 0 50px;
  width: auto;
}

ul.count_down.white li {
  color: #ffffff;
}

ul.count_down li p:nth-child(1) {
  font-size: 70px;
  font-weight: bold;
  line-height: normal;
  margin: 0 0 0 0;
  transition: 1s;
}

ul.count_down li p:nth-child(2) {
  color: #232323;
  font-size: 14px;
  line-height: normal;
  text-align: center;
  text-transform: uppercase;
  margin: 10px 0 0 0;
  transition: 1s;
  font-weight: 500;
}

ul.count_down.white li p:nth-child(2) {
  color: #ffffff;
}

.count-down-form form {
  margin: 0 auto;
  position: relative;
}

.count-down-form form .form-placeholder {
  border-radius: 40px;
  border: none;
  outline: none;
  padding: 25.5px;
  width: 100%;
  font-size: 16px;
  font-weight: 100;
  color: #232323;
  text-align: left;
}

.count-down-form form .email-placeholder {
  background: transparent;
  width: 100%;
  border: 1px solid #a5a5a5;
  border-radius: 40px;
  display: flex;
}

.count-down-form form .button {
  transition: all linear 300ms;
  margin: 5px 5px 5px 2px;
}

/* ----- coming soon end ----- */

/* ----- Pricing ----- */

.pricing-item {
  position: relative;
  padding: 2.5rem 1rem;
  margin-bottom: 10px;
  margin-top: 10px;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.12);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -webkit-border-radius: 0;
  text-align: center;
  overflow: hidden;
  background: #fff;
}

.pricing-item .pricing-price {
  padding: 15px 0 14px 0;
  border-top: 1px solid rgba(139, 144, 157, 0.18);
  border-bottom: 1px solid rgba(139, 144, 157, 0.18);
}

.pricing-item.active .pricing-price {
  border-top: 1px solid rgba(255, 255, 255, 0.18);
  border-bottom: 1px solid rgba(255, 255, 255, 0.18);
}

.pricing-item .pricing-currency {
  font-size: 3rem;
  font-weight: 300;
}

.pricing-list {
  font-size: 14px;
  padding: 25px 0;
  display: inline-block;
}

.pricing-list li {
  margin: 14px 0 14px 25px;
  text-align: left;
  font-size: 1rem;
  position: relative;
}

.price-included {
  position: relative;
  display: table;
  margin: 14px auto;
  padding: 2px 0 10px 5px;
  text-align: left;
  font-size: 1.1rem;
}

.pricing-item.active {
  background: #26313c;
}

.pricing-item.active .button {
  border-color: #fff;
  background: #26313c;
  transition: 0.2s all;
}

.pricing-item .button {
  border-color: #fff;
  background: #38c172;
  color: #fff;
  display: table;
  margin: 0 auto;
}

.pricing-item.active,
.pricing-item.active .darkcolor {
  color: #fff;
}

.pricing-item .button:hover {
  background: #fff;
  color: #384141;
}

.pricing-item .button:focus {
  background: #fff;
  border-color: #26313c;
  color: #384141;
}

/*price sale*/

.pricing-item.sale::before,
.shopping-box .sale::before {
  content: "";
  font-family: "Font Awesome 5 Free", serif;
  font-weight: 900;
  position: absolute;
  top: -30px;
  right: 15px;
  font-size: 60px;
  color: #E84455;
  z-index: 1;
}

.pricing-item.gray.sale::before {
  color: #4A5158;
}

.shopping-box .sale::before {
  z-index: 100;
}

.pricing-item.sale::after,
.shopping-box .sale::after {
  content: "- " attr(data-sale) "%";
  position: absolute;
  top: 6px;
  right: 21px;
  font-size: 14px;
  color: #fff;
  z-index: 2;
}

.shopping-box .sale::after {
  z-index: 101;
}

.pricing-item.sale:hover::after,
.shopping-box .sale:hover::after {
  content: "Sale";
  right: 24px;
}

.pricing-list li::before,
.price-included::before {
  content: "";
  font-family: "Font Awesome 5 Free", serif;
  font-weight: 900;
  position: absolute;
  top: 2px;
  left: -25px;
  color: #B2CB20;
}

.pricing-list li.price-not::before {
  content: "";
  font-family: "Font Awesome 5 Free", serif;
  font-weight: 900;
  position: absolute;
  top: 2px;
  left: -23px;
  color: #E84455;
}

.pricing-item.active .pricing-list li::before {
  color: #fff;
}

.price-toggle-wrapper {
  position: relative;
  background: #fff;
  border: 1px solid transparent;
  border-radius: 30px;
  overflow: hidden;
  display: inline-flex;
}

.price-toggle-wrapper .Pricing-toggle-button {
  padding: 10px 110px;
  user-select: none;
  cursor: pointer;
  z-index: 1;
  font-size: 14px;
  font-weight: 600;
  position: relative;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.price-toggle-wrapper .Pricing-toggle-button.active {
  color: #fff;
  cursor: default;
}

.price-toggle-wrapper .Pricing-toggle-button.month::before {
  content: "";
  position: absolute;
  width: 105%;
  height: 100%;
  border-radius: 30px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  overflow: hidden;
  transform: translateX(95%);
  z-index: -1;
  -webkit-background-size: 200% auto;
  background-size: 200% auto;
  background-image: -webkit-gradient(linear, left top, right top, from(#38c172), color-stop(51%, #23ced5), to(#38c172));
  background-image: -webkit-linear-gradient(left, #38c172 0%, #23ced5 51%, #38c172 100%);
  background-image: -moz-linear-gradient(left, #38c172 0%, #23ced5 51%, #38c172 100%);
  background-image: -o-linear-gradient(left, #38c172 0%, #23ced5 51%, #38c172 100%);
  background-image: linear-gradient(to right, #38c172 0%, #23ced5 51%, #38c172 100%);
  background-position: right center;
}

.price-toggle-wrapper .Pricing-toggle-button.month.active::before {
  transform: translateX(-5%);
  background-position: left center;
}

.pricing-price {
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  transform: scale(1);
}

@media (max-width: 992px) {
  .pricing-item .pricing-currency {
    font-size: 2.5rem;
  }

  .pricing-list li {
    font-size: 0.9375rem;
  }

  .price-toggle-wrapper .Pricing-toggle-button {
    padding: 10px 70px;
  }

  .testi-box .top90 {
    margin-top: 10px;
  }
}

@media (max-width: 767px) {
  .price-toggle-wrapper .Pricing-toggle-button {
    padding: 10px 40px;
    font-size: 13px;
  }

  #our-blog .news_item.last-blog {
    margin-bottom: 15px;
  }
}

@media (max-width: 576px) {
  .price-toggle-wrapper .Pricing-toggle-button {
    padding: 10px 30px;
  }

  .carousel-shop-detail .owl-nav button,
  #carousel-gallery-detail .owl-nav button {
    transform: translateX(0) !important;
  }

  #syncCarousel .owl-stage {
    pointer-events: none;
  }
}

/* ----- Pricing ends ----- */

/*----- Footer -----*/

.footer_logo {
  width: 90px;
}

.footer_logo,
.footer_logo > img,
.readmorebtn,
ul.links li a,
ul.links li a::before,
ul.hours_links li > span {
  display: inline-block;
}

ul.latest_news li,
ul.latest_news li a,
ul.hours_links li {
  display: block;
}

ul.hours_links li,
ul.links li a,
ul.hours_links li > span {
  position: relative;
}

.footer_logo > img {
  width: 100%;
}

footer .footer_panel .address-item > span {
  width: 30px;
  font-size: 30px;
}

ul.links li a {
  color: #ffffff;
  margin-bottom: 10px;
}

ul.links li a::before {
  color: #475869;
  margin-right: 5px;
  content: "";
  vertical-align: baseline;
}

.bg-light-dark ul.links li a::before {
  color: #7596a8;
}

ul.links li a:hover::before {
  padding-right: 5px;
}

ul.latest_news li {
  padding-bottom: 15px;
  border-bottom: 1px solid #4e4e4e;
  margin-bottom: 15px;
}

footer p,
ul.latest_news li .date {
  font-size: 13px;
}

ul.hours_links li:first-child {
  padding-top: 0;
}

ul.hours_links li {
  padding: 8px 0;
  z-index: 1;
}

ul.hours_links li::before {
  height: 1px;
  width: 100%;
  border-bottom: 1px dashed #fff;
  top: 56%;
  margin-top: -1px;
}

ul.hours_links li:first-child::before {
  top: 50%;
}

ul.hours_links li::before,
ul.hours_links li span:last-child {
  position: absolute;
}

ul.hours_links li > span {
  z-index: 1;
  padding-right: 5px;
}

.bgdark ul.hours_links li > span {
  background: #26313c;
}

.bg-light-dark ul.hours_links li > span {
  background: #556772;
}

ul.hours_links li span:last-child {
  right: 0;
  padding-left: 5px;
}

ul.latest_news li,
ul.links li a,
ul.hours_links li,
.readmorebtn {
  font-size: 14px;
}

ul.links li a:hover,
ul.links li a:focus,
ul.latest_news li a:hover,
ul.latest_news li a:focus,
.footer_panel p > a:hover,
.readmorebtn:hover,
.readmorebtn:focus {
  color: #38c172;
}

ul.latest_news li span.defaultcolor {
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

ul.latest_news li a:hover + span.defaultcolor,
ul.latest_news li a:focus + span.defaultcolor {
  color: #fff !important;
}

/* Footer ends */

@media (max-width: 1400px) {
  html {
    font-size: 15px;
  }
}

@media (max-width: 1266px) {
  html {
    font-size: 14px;
  }
}

@media (max-width: 1024px) {
  h2 {
    font-size: 2.75rem;
  }

  h3 {
    font-size: 1.375rem;
  }
}

@media (max-width: 992px) {
  h2 {
    font-size: 2.5rem;
  }

  p {
    font-size: 13px;
  }

  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    padding-right: 15px;
    padding-left: 15px;
  }

  #shop .findus .form-group {
    margin-bottom: 20px !important;
    width: 100%;
  }

  #shop .findus .button,
  .findus .form-group {
    width: 100%;
  }

  .navbar-brand.center-brand {
    position: relative;
    top: auto;
    margin: 16px 0;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }

  .padding_testi {
    padding: 3.25rem 0 9.75rem 0;
  }

  .process-wrapp li {
    padding: 60px 15px 0 15px;
  }
}

@media (max-width: 767px) {
  .revicon {
    display: none;
    visibility: hidden;
    opacity: 0;
  }

  .feature-item.innovative-border.arr-left::before {
    top: auto;
    bottom: -8px;
    right: calc(50% - 7px);
    transform: rotate(45deg);
  }

  .feature-item.innovative-border.arr-right::before {
    right: auto;
    top: -8px;
    left: calc(50% - 7px);
    transform: rotate(45deg);
  }

  .padding {
    padding: 6rem 0;
  }

  .padding_top {
    padding-top: 6rem;
  }

  .padding_bottom {
    padding-bottom: 6rem;
  }

  .margin {
    margin: 6rem 0;
  }

  .margin_bottom {
    margin-bottom: 6rem;
  }

  .margin_top {
    margin-top: 4rem;
  }

  .padding_half {
    padding: 2rem 0;
  }

  .margin_half {
    margin: 2rem 0;
  }

  .padding_bottom_half {
    padding-bottom: 2rem;
  }

  .margin_bottom_half {
    margin-bottom: 2rem;
  }

  .padding_top_half {
    padding-top: 2rem;
  }

  .heading_space {
    margin-bottom: 2rem;
  }

  .team-box:hover .team-content h4 {
    margin-top: -10px;
  }

  .navbar-nav .nav-link:focus,
  .dropdown-menu .dropdown-item:focus,
  .dropdown-menu.megamenu .dropdown-item:focus {
    color: #38c172;
  }

  .transparent-form textarea {
    height: 40px;
    min-height: 40px;
    overflow-y: hidden;
  }

  .video-play-button {
    top: 100%;
    right: 50%;
    transform: translate(50%, -50%);
  }

  .process-wrapp li {
    padding: 50px 15px 0 15px;
  }

  .forget-buttons {
    display: flex;
    justify-content: space-between;
  }

  .forget-buttons button {
    width: 47%;
  }

  .cbp-l-filters .cbp-filter-item {
    margin: 0 0.3rem 0.9rem;
  }

  .heading .divider-left {
    margin: 10px auto;
  }

  .video-content-setting {
    padding: 5rem 4rem;
  }

  #particles-text-morph {
    width: 100% !important;
  }
}

@media (max-width: 576px) {
  html {
    font-size: 13px;
  }

  .container {
    max-width: 98% !important;
  }

  .profile .p_pic,
  .profile .profile_text {
    display: block;
  }

  .profile_bg {
    padding: 5px;
  }

  .title-wrap h3 {
    display: block;
    margin: 3px auto;
    text-align: center;
    float: unset !important;
  }

  .title-wrap ul {
    float: unset !important;
    display: flex;
    justify-content: center;
  }

  .apply_coupon .coupon a {
    display: block;
    width: 100%;
  }

  .dark-slider h2,
  .light-slider h2 {
    font-size: 34px !important;
  }

  .dark-slider p,
  .light-slider p {
    font-size: 16px !important;
    line-height: 1.4 !important;
  }

  .process-wrapp li {
    padding: 35px 15px 0 15px;
  }

  .page-header .page-titles {
    transform: translateY(20px);
  }

  #pagepiling .darkcolor {
    color: #fff;
  }

  .sidemenu_btn {
    margin-right: 0;
  }
}

@media (max-width: 424px) {
  .side-menu .btn-close {
    margin-right: 0.7rem;
  }

  #services-slider .service-box > span {
    padding-top: 0.5rem;
  }

  .eny_profile .profile_text .readmorebtn {
    top: auto;
    bottom: -20px;
  }
}

@media (max-width: 360px) {
  .tabset-list a,
  .accordion-item a {
    display: block;
    padding: 10px 1.3rem;
  }

  #testimonial-slider .owl-nav {
    width: 270px;
  }

  .eny_profile .profile_photo,
  .eny_profile .profile_text {
    display: block;
    margin: 10px auto;
    text-align: center;
  }

  .dark-slider h2,
  .light-slider h2 {
    font-size: 28px !important;
  }
}

/*scroll bar styling*/

::-webkit-scrollbar-track {
  background-color: #515252;
}

::-webkit-scrollbar {
  width: 7px;
  background-color: #38c172;
}

::-webkit-scrollbar-thumb {
  background: #38c172;
}

::-webkit-scrollbar-thumb:hover {
  box-shadow: inset 0 0 100px 20px #01a23c;
}

.info-box-image .icon {
  max-width: 40%;
}

/**************************** Personalization ****************************************/

/*
.btn {
    display: inline-block;
    font-weight: 600;
    color: #757575;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: .9375rem;
    line-height: 1.9;
    border-radius: .25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out
}

@media (prefers-reduced-motion: reduce) {
    .btn {
        transition: none
    }
}

.btn:hover {
    color: #757575;
    text-decoration: none
}

.btn:focus,
.btn.focus {
    outline: 0;
    box-shadow: none
}

.btn.disabled,
.btn:disabled {
    opacity: .65
}

a.btn.disabled,
fieldset:disabled a.btn {
    pointer-events: none
}

.btn-primary {
    color: #fff;
    background-color: $primary;
    border-color: $primary
}

.btn-primary:hover {
    color: #fff;
    background-color: #3c96ff;
    border-color: #3c96ff;
    box-shadow: 0 1px 10px rgba(80, 161, 255, 0.4)
}

.btn-primary:focus,
.btn-primary.focus {
    color: #fff;
    box-shadow: 0 0 0 0rem rgba(80, 161, 255, 0.5)
}

.btn-primary.disabled,
.btn-primary:disabled {
    background-color: $primary;
    border-color: $primary
}

.btn-primary:not([disabled]):not(.disabled):active,
.btn-primary:not([disabled]):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #278bff;
    border-color: #278bff;
    box-shadow: 0 0 0 0rem rgba(80, 161, 255, 0.5)
}

.btn-secondary {
    color: #757575;
    background-color: #e9ecf0;
    border-color: #e9ecf0
}

.btn-secondary:hover {
    color: #757575;
    background-color: #dde2e8;
    border-color: #dde2e8;
    box-shadow: 0 1px 10px rgba(233, 236, 240, 0.4)
}

.btn-secondary:focus,
.btn-secondary.focus {
    color: #757575;
    box-shadow: 0 0 0 0rem rgba(233, 236, 240, 0.5)
}

.btn-secondary.disabled,
.btn-secondary:disabled {
    background-color: #e9ecf0;
    border-color: #e9ecf0
}

.btn-secondary:not([disabled]):not(.disabled):active,
.btn-secondary:not([disabled]):not(.disabled).active,
.show>.btn-secondary.dropdown-toggle {
    color: #757575;
    background-color: #d1d7df;
    border-color: #d1d7df;
    box-shadow: 0 0 0 0rem rgba(233, 236, 240, 0.5)
}

.btn-success {
    color: #fff;
    background-color: #3cd458;
    border-color: #3cd458
}

.btn-success:hover {
    color: #fff;
    background-color: #2dce4b;
    border-color: #2dce4b;
    box-shadow: 0 1px 10px rgba(60, 212, 88, 0.4)
}

.btn-success:focus,
.btn-success.focus {
    color: #fff;
    box-shadow: 0 0 0 0rem rgba(60, 212, 88, 0.5)
}

.btn-success.disabled,
.btn-success:disabled {
    background-color: #3cd458;
    border-color: #3cd458
}

.btn-success:not([disabled]):not(.disabled):active,
.btn-success:not([disabled]):not(.disabled).active,
.show>.btn-success.dropdown-toggle {
    color: #fff;
    background-color: #2abd45;
    border-color: #2abd45;
    box-shadow: 0 0 0 0rem rgba(60, 212, 88, 0.5)
}

.btn-info {
    color: #fff;
    background-color: #926dde;
    border-color: #926dde
}

.btn-info:hover {
    color: #fff;
    background-color: #865cda;
    border-color: #865cda;
    box-shadow: 0 1px 10px rgba(146, 109, 222, 0.4)
}

.btn-info:focus,
.btn-info.focus {
    color: #fff;
    box-shadow: 0 0 0 0rem rgba(146, 109, 222, 0.5)
}

.btn-info.disabled,
.btn-info:disabled {
    background-color: #926dde;
    border-color: #926dde
}

.btn-info:not([disabled]):not(.disabled):active,
.btn-info:not([disabled]):not(.disabled).active,
.show>.btn-info.dropdown-toggle {
    color: #fff;
    background-color: #794cd6;
    border-color: #794cd6;
    box-shadow: 0 0 0 0rem rgba(146, 109, 222, 0.5)
}

.btn-warning {
    color: #fff;
    background-color: #ffba00;
    border-color: #ffba00
}

.btn-warning:hover {
    color: #fff;
    background-color: #ebab00;
    border-color: #ebab00;
    box-shadow: 0 1px 10px rgba(255, 186, 0, 0.4)
}

.btn-warning:focus,
.btn-warning.focus {
    color: #fff;
    box-shadow: 0 0 0 0rem rgba(255, 186, 0, 0.5)
}

.btn-warning.disabled,
.btn-warning:disabled {
    background-color: #ffba00;
    border-color: #ffba00
}

.btn-warning:not([disabled]):not(.disabled):active,
.btn-warning:not([disabled]):not(.disabled).active,
.show>.btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #d69c00;
    border-color: #d69c00;
    box-shadow: 0 0 0 0rem rgba(255, 186, 0, 0.5)
}

.btn-danger {
    color: #fff;
    background-color: #ff4954;
    border-color: #ff4954
}

.btn-danger:hover {
    color: #fff;
    background-color: #ff3541;
    border-color: #ff3541;
    box-shadow: 0 1px 10px rgba(255, 73, 84, 0.4)
}

.btn-danger:focus,
.btn-danger.focus {
    color: #fff;
    box-shadow: 0 0 0 0rem rgba(255, 73, 84, 0.5)
}

.btn-danger.disabled,
.btn-danger:disabled {
    background-color: #ff4954;
    border-color: #ff4954
}

.btn-danger:not([disabled]):not(.disabled):active,
.btn-danger:not([disabled]):not(.disabled).active,
.show>.btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ff202e;
    border-color: #ff202e;
    box-shadow: 0 0 0 0rem rgba(255, 73, 84, 0.5)
}

.btn-light {
    color: #757575;
    background-color: #f8f9fa;
    border-color: #f8f9fa
}

.btn-light:hover {
    color: #757575;
    background-color: #eceff2;
    border-color: #eceff2;
    box-shadow: 0 1px 10px rgba(248, 249, 250, 0.4)
}

.btn-light:focus,
.btn-light.focus {
    color: #757575;
    box-shadow: 0 0 0 0rem rgba(248, 249, 250, 0.5)
}

.btn-light.disabled,
.btn-light:disabled {
    background-color: #f8f9fa;
    border-color: #f8f9fa
}

.btn-light:not([disabled]):not(.disabled):active,
.btn-light:not([disabled]):not(.disabled).active,
.show>.btn-light.dropdown-toggle {
    color: #757575;
    background-color: #e0e5e9;
    border-color: #e0e5e9;
    box-shadow: 0 0 0 0rem rgba(248, 249, 250, 0.5)
}

.btn-dark {
    color: #fff;
    background-color: #191919;
    border-color: #191919
}

.btn-dark:hover {
    color: #fff;
    background-color: #0f0f0f;
    border-color: #0f0f0f;
    box-shadow: 0 1px 10px rgba(25, 25, 25, 0.4)
}

.btn-dark:focus,
.btn-dark.focus {
    color: #fff;
    box-shadow: 0 0 0 0rem rgba(25, 25, 25, 0.5)
}

.btn-dark.disabled,
.btn-dark:disabled {
    background-color: #191919;
    border-color: #191919
}

.btn-dark:not([disabled]):not(.disabled):active,
.btn-dark:not([disabled]):not(.disabled).active,
.show>.btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #050505;
    border-color: #050505;
    box-shadow: 0 0 0 0rem rgba(25, 25, 25, 0.5)
}

.btn-outline-primary {
    color: $primary;
    background-color: transparent;
    background-image: none;
    border-color: $primary
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
    color: $primary;
    box-shadow: 0 0 0 0rem rgba(80, 161, 255, 0.5)
}

.btn-outline-primary:hover {
    color: #fff;
    background-color: $primary;
    border-color: $primary;
    box-shadow: 0 1px 10px rgba(80, 161, 255, 0.4)
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
    color: $primary;
    background-color: transparent
}

.btn-outline-primary:not([disabled]):not(.disabled):active,
.btn-outline-primary:not([disabled]):not(.disabled).active,
.show>.btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: $primary;
    border-color: $primary;
    box-shadow: 0 0 0 0rem rgba(80, 161, 255, 0.5)
}

.btn-outline-secondary {
    color: #e9ecf0;
    background-color: transparent;
    background-image: none;
    border-color: #e9ecf0
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
    color: #e9ecf0;
    box-shadow: 0 0 0 0rem rgba(233, 236, 240, 0.5)
}

.btn-outline-secondary:hover {
    color: #757575;
    background-color: #e9ecf0;
    border-color: #e9ecf0;
    box-shadow: 0 1px 10px rgba(233, 236, 240, 0.4)
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
    color: #e9ecf0;
    background-color: transparent
}

.btn-outline-secondary:not([disabled]):not(.disabled):active,
.btn-outline-secondary:not([disabled]):not(.disabled).active,
.show>.btn-outline-secondary.dropdown-toggle {
    color: #757575;
    background-color: #e9ecf0;
    border-color: #e9ecf0;
    box-shadow: 0 0 0 0rem rgba(233, 236, 240, 0.5)
}

.btn-outline-success {
    color: #3cd458;
    background-color: transparent;
    background-image: none;
    border-color: #3cd458
}

.btn-outline-success:focus,
.btn-outline-success.focus {
    color: #3cd458;
    box-shadow: 0 0 0 0rem rgba(60, 212, 88, 0.5)
}

.btn-outline-success:hover {
    color: #fff;
    background-color: #3cd458;
    border-color: #3cd458;
    box-shadow: 0 1px 10px rgba(60, 212, 88, 0.4)
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
    color: #3cd458;
    background-color: transparent
}

.btn-outline-success:not([disabled]):not(.disabled):active,
.btn-outline-success:not([disabled]):not(.disabled).active,
.show>.btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #3cd458;
    border-color: #3cd458;
    box-shadow: 0 0 0 0rem rgba(60, 212, 88, 0.5)
}

.btn-outline-info {
    color: #926dde;
    background-color: transparent;
    background-image: none;
    border-color: #926dde
}

.btn-outline-info:focus,
.btn-outline-info.focus {
    color: #926dde;
    box-shadow: 0 0 0 0rem rgba(146, 109, 222, 0.5)
}

.btn-outline-info:hover {
    color: #fff;
    background-color: #926dde;
    border-color: #926dde;
    box-shadow: 0 1px 10px rgba(146, 109, 222, 0.4)
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
    color: #926dde;
    background-color: transparent
}

.btn-outline-info:not([disabled]):not(.disabled):active,
.btn-outline-info:not([disabled]):not(.disabled).active,
.show>.btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #926dde;
    border-color: #926dde;
    box-shadow: 0 0 0 0rem rgba(146, 109, 222, 0.5)
}

.btn-outline-warning {
    color: #ffba00;
    background-color: transparent;
    background-image: none;
    border-color: #ffba00
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
    color: #ffba00;
    box-shadow: 0 0 0 0rem rgba(255, 186, 0, 0.5)
}

.btn-outline-warning:hover {
    color: #fff;
    background-color: #ffba00;
    border-color: #ffba00;
    box-shadow: 0 1px 10px rgba(255, 186, 0, 0.4)
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
    color: #ffba00;
    background-color: transparent
}

.btn-outline-warning:not([disabled]):not(.disabled):active,
.btn-outline-warning:not([disabled]):not(.disabled).active,
.show>.btn-outline-warning.dropdown-toggle {
    color: #fff;
    background-color: #ffba00;
    border-color: #ffba00;
    box-shadow: 0 0 0 0rem rgba(255, 186, 0, 0.5)
}

.btn-outline-danger {
    color: #ff4954;
    background-color: transparent;
    background-image: none;
    border-color: #ff4954
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
    color: #ff4954;
    box-shadow: 0 0 0 0rem rgba(255, 73, 84, 0.5)
}

.btn-outline-danger:hover {
    color: #fff;
    background-color: #ff4954;
    border-color: #ff4954;
    box-shadow: 0 1px 10px rgba(255, 73, 84, 0.4)
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
    color: #ff4954;
    background-color: transparent
}

.btn-outline-danger:not([disabled]):not(.disabled):active,
.btn-outline-danger:not([disabled]):not(.disabled).active,
.show>.btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #ff4954;
    border-color: #ff4954;
    box-shadow: 0 0 0 0rem rgba(255, 73, 84, 0.5)
}

.btn-outline-light {
    color: #f8f9fa;
    background-color: transparent;
    background-image: none;
    border-color: #f8f9fa
}

.btn-outline-light:focus,
.btn-outline-light.focus {
    color: #f8f9fa;
    box-shadow: 0 0 0 0rem rgba(248, 249, 250, 0.5)
}

.btn-outline-light:hover {
    color: #757575;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
    box-shadow: 0 1px 10px rgba(248, 249, 250, 0.4)
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent
}

.btn-outline-light:not([disabled]):not(.disabled):active,
.btn-outline-light:not([disabled]):not(.disabled).active,
.show>.btn-outline-light.dropdown-toggle {
    color: #757575;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
    box-shadow: 0 0 0 0rem rgba(248, 249, 250, 0.5)
}

.btn-outline-dark {
    color: #191919;
    background-color: transparent;
    background-image: none;
    border-color: #191919
}

.btn-outline-dark:focus,
.btn-outline-dark.focus {
    color: #191919;
    box-shadow: 0 0 0 0rem rgba(25, 25, 25, 0.5)
}

.btn-outline-dark:hover {
    color: #fff;
    background-color: #191919;
    border-color: #191919;
    box-shadow: 0 1px 10px rgba(25, 25, 25, 0.4)
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
    color: #191919;
    background-color: transparent
}

.btn-outline-dark:not([disabled]):not(.disabled):active,
.btn-outline-dark:not([disabled]):not(.disabled).active,
.show>.btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #191919;
    border-color: #191919;
    box-shadow: 0 0 0 0rem rgba(25, 25, 25, 0.5)
}

.btn-link {
    font-weight: 400;
    color: $primary;
    text-decoration: none
}

.btn-link:hover {
    color: #0478ff;
    text-decoration: underline
}

.btn-link:focus,
.btn-link.focus {
    text-decoration: underline;
    box-shadow: none
}

.btn-link:disabled,
.btn-link.disabled {
    color: #868e96;
    pointer-events: none
}

.btn-lg,
.btn-group-lg>.btn,
.input-group-lg>.input-group-prepend>.btn,
.input-group-lg>.input-group-append>.btn {
    padding: .375rem 1rem;
    font-size: 1.0625rem;
    line-height: 2.2;
    border-radius: .3rem
}

.btn-sm,
.btn-group-sm>.btn,
.input-group-sm>.input-group-prepend>.btn,
.input-group-sm>.input-group-append>.btn {
    padding: .375rem .5rem;
    font-size: .9375rem;
    line-height: 1.5;
    border-radius: .125rem
}

.btn-block {
    display: block;
    width: 100%
}

.btn-block+.btn-block {
    margin-top: .5rem
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
    width: 100%
}
*/

